import React from "react";
import styled from "styled-components";

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonContent = styled.button`
  border: 1px solid ${({ color }) => color};
  color: ${({ color }) => color};
  background-color: transparent;
  border-radius: 18px;
  padding: 6px 20px;
  height: fit-content;
  cursor: pointer;
`;

const Button = ({ title, color, link }) => {
  return (
    <ButtonWrap>
      <ButtonContent color={color} onClick={link}>
        {title}
      </ButtonContent>
    </ButtonWrap>
  );
};

export default Button;
