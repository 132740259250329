import React, { useState } from "react";
import styled from "styled-components";

const DonateWrap = styled.div`
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  background-color: #f5f6ff;
  @media screen and (max-width: 767px) {
    max-width: 430px;
  }
`;

const DonateContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 1279px) {
    margin: 0 16px;
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

const DonateContent = styled.div`
  flex: ${({ flex }) => flex};
  margin-bottom: 40px;

  h2 {
    color: #0e3975;
  }
  h3 {
    color: #134d9e;
  }
  p {
    color: #777;
    font-size: 0.9em;
  }
  h5 {
    font-weight: 600;
    font-size: 0.9em;
    color: rgb(29 114 235);
  }
`;

const AccountWrap = styled.div`
  h5 {
    margin-block-end: 0;
    color: rgb(29 114 235);
  }
`;

const AccFlex = styled.div`
  margin-top: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const AccInput = styled.input`
  border-radius: 8px;
  border: 1px solid rgba(200, 200, 200, 0.7);
  height: 30px;
  text-align: center;
  font-weight: 600;
  font-size: 1em;
  color: #777;
  background-color: transparent;
`;

const CopyBtn = styled.button`
  border-radius: 8px;
  border: none;
  height: 30px;
  width: 80px;
  text-align: center;
  font-weight: 600;
  color: white;
  background-color: rgb(29 114 235);
  cursor: pointer;
  &:hover {
    background-color: #81adeb;
  }
`;

const bankAcc = [
  {
    title: "우리은행",
    acc: "1006-980-321700",
    text: "우리은행 계좌로 복사되었습니다.",
  },
  {
    title: "KEB하나은행",
    acc: "573-910004-21105",
    text: "KEB하나은행 계좌로 복사되었습니다.",
  },
  {
    title: "KB국민은행",
    acc: "387201-01-088376",
    text: "KB국민은행 계좌로 복사되었습니다.",
  },
  {
    title: "KEB Hana global",
    acc: "57391001243932",
    text: "Copied KEB hana Bank Account.",
  },
];

const GlobalDonate = () => {
  const [copySuccess, setCopySuccess] = useState("");

  const handleCopy = (acc, text) => {
    navigator.clipboard
      .writeText(acc)
      .then(() => {
        setCopySuccess(text);
        setTimeout(() => {
          setCopySuccess("");
        }, 2000);
      })
      .then(() => {
        alert(text);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  return (
    <DonateWrap>
      <DonateContainer>
        <DonateContent flex={"1 2 0"}>
          <h2>후원계좌 안내</h2>
        </DonateContent>
        <DonateContent flex={"2 2 0"}>
          <h3>국내에서 후원</h3>
          <h5>예금주: 세계인터넷선교협의회</h5>
          {bankAcc.slice(0, 3).map((bank, index) => (
            <AccountWrap key={index}>
              <h5>{bank.title}</h5>
              <AccFlex>
                <AccInput
                  type="text"
                  value={bank.acc}
                  readOnly
                  style={{ marginRight: "10px" }}
                />
                <CopyBtn onClick={() => handleCopy(bank.acc, bank.text)}>
                  복사
                </CopyBtn>
              </AccFlex>
            </AccountWrap>
          ))}
        </DonateContent>
        <DonateContent flex={"2 2 0"}>
          <h3>해외에서 후원</h3>
          <p>
            은행: <b>KEB Hana Bank</b>
          </p>
          <p>
            코드 (SWIFT BIC): <b>KOEXKRSE</b>
          </p>
          {bankAcc.slice(3).map((bank, index) => (
            <AccountWrap key={index}>
              <h5>{bank.title}</h5>
              <AccFlex>
                <AccInput
                  type="text"
                  value={bank.acc}
                  readOnly
                  style={{ marginRight: "10px" }}
                />
                <CopyBtn onClick={() => handleCopy(bank.acc, bank.text)}>
                  복사
                </CopyBtn>
              </AccFlex>
              <p>
                이름: <b>Society for World Internet Mission (SWIM)</b>
              </p>
              <p>
                주소:{" "}
                <b>
                  2FL Yuhan Bldg. 4, Seobinggo-ro 51-gil, Yongsan-gu, Seoul,
                  Republic of Korea
                </b>
              </p>
            </AccountWrap>
          ))}
        </DonateContent>
      </DonateContainer>
    </DonateWrap>
  );
};

export default GlobalDonate;
