import { Outlet, createBrowserRouter } from "react-router-dom";
import Structure from "./pages/structure";
import Page from "./components/page";
import Gnb from "./components/Gnb";
import Footer from "./components/footer";
import Donate from "./pages/Donate";
import styled from "styled-components";
import Login from "./pages/Login";
import Home from "./pages/Home";
import TokenRefresh from "./components/refresh";
import Subscribe from "./pages/subscribe";
import PrivateRoute from "./components/privateRoute";
import Posts from "./pages/Posts";
import Write from "./pages/Write";
import QtList from "./pages/QtList";
import Dailybible from "./pages/Qt/Dailybible";
import Life from "./pages/Qt/Life";
import ErrorPage from "./pages/ErrorPage";
import Spurgeon from "./pages/Qt/Spurgeon";
import OurdailyBread from "./pages/Qt/OurdailyBread";
import Living from "./pages/Qt/LivingLife";
import JpLife from "./pages/Qt/JpLife";
import DailybibleEng from "./pages/Qt/DailyBibleEng";
import Article from "./pages/Article";
import { HelmetProvider } from "react-helmet-async";
import ArLife from "./pages/Qt/ArLife";
import LikeJesus from "./pages/Qt/LikeJesus";
import Dawn from "./pages/Qt/Dawn";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const OutWrap = styled.div`
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Layout = () => {
  const [refresh, setRefresh] = useState(true);
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();

  useEffect(() => {
    if (token === null || token === "") {
      setRefresh(false);
    }
  }, [token, dispatch]);

  return (
    <>
      <HelmetProvider>
        <Gnb />
        <OutWrap>
          <Outlet />
        </OutWrap>
        <Footer />
        {refresh ? <TokenRefresh /> : ""}
      </HelmetProvider>
    </>
  );
};

const QtLayout = () => {
  return (
    <>
      <QtList /> {/* 항상 렌더링되는 QtList 컴포넌트 */}
      <Outlet />
    </>
  );
};

const paths = [
  {
    // root
    path: "/",
    element: <Layout />,
    children: [
      {
        // home
        path: "/",
        element: <Home />,
      },
      {
        // article
        path: "/:id",
        element: <Page />,
      },
      {
        // structure
        path: "/structure",
        element: <Structure />,
      },
      {
        // article
        path: "/news",
        element: <Article />,
      },
      {
        // qt
        path: "/qt",
        element: <QtLayout />,
        children: [
          {
            path: "",
          },
          {
            path: "dailybible",
            element: <Dailybible />,
          },
          {
            path: "life",
            element: <Life />,
          },
          {
            path: "spurgeon",
            element: <Spurgeon />,
          },
          {
            path: "odb",
            element: <OurdailyBread />,
          },
          {
            path: "living",
            element: <Living />,
          },
          {
            path: "jplife",
            element: <JpLife />,
          },
          {
            path: "dailybibleeng",
            element: <DailybibleEng />,
          },
          {
            path: "arlife",
            element: <ArLife />,
          },
          {
            path: "likejesus",
            element: <LikeJesus />,
          },
          {
            path: "dawn",
            element: <Dawn />,
          },
        ],
      },
      {
        // login
        path: "/login",
        element: <Login />,
      },
      {
        // subscribe
        path: "/subscribe",
        element: <Subscribe />,
      },
      {
        path: "/my/posts",
        element: <PrivateRoute element={<Posts />} />,
      },
      {
        path: "/my/write",
        element: <Write />,
      },
      {
        // 404
        path: "/*",
        element: <ErrorPage />,
      },
    ],
  },
];

export const router = createBrowserRouter(paths);
