export const MODAL_OPEN = "MODAL_OPEN";
export const MODAL_CLOSE = "MODAL_CLOSE";

export const modalOpen = (type) => ({
  type: MODAL_OPEN,
  payload: type,
});

export const modalClose = () => ({
  type: MODAL_CLOSE,
});
