import React, { useState } from "react";
import axios from "axios";
import MDEditor from "@uiw/react-markdown-editor";
import styled from "styled-components";

const WriteFlex = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
`;

function Write() {
  const [title, setTitle] = useState("");
  const [markdown, setMarkdown] = useState("");
  const [imageFiles, setImageFiles] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);

  const handleMarkdownChange = (e) => {
    setMarkdown(e.target.value);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  // 이미지 선택 핸들러 (로컬 미리보기 제공 및 마크다운에 이미지 추가)
  const handleImageSelect = (e) => {
    const files = Array.from(e.target.files);
    setImageFiles(files);

    const imagePreviews = files.map((file) => URL.createObjectURL(file));
    setPreviewImages(imagePreviews);

    // 선택된 이미지들을 마크다운 텍스트에 로컬 URL로 추가
    const imageMarkdowns = imagePreviews
      .map((src, index) => `![image${index}](${src})`)
      .join("\n");
    setMarkdown((prevMarkdown) => `${prevMarkdown}\n${imageMarkdowns}`);
  };

  const handlePost = async () => {
    try {
      // JWT 토큰 가져오기
      const token = localStorage.getItem("token");

      if (!token) {
        console.error("No JWT token found in local storage.");
        alert("로그인이 필요합니다.");
        return;
      }

      console.log("Uploading images with token:", token);

      // 이미지 업로드
      const uploadedImageUrls = await Promise.all(
        imageFiles.map(async (file) => {
          const formData = new FormData();
          formData.append("image", file);
          const response = await axios.post(
            "https://data.swim.org/api/upload",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`, // JWT 토큰 추가
              },
            }
          );
          return response.data.url; // 업로드된 이미지의 실제 URL 반환
        })
      );

      // 로컬 이미지 URL을 업로드된 이미지 URL로 대체
      let updatedMarkdown = markdown;
      uploadedImageUrls.forEach((url, index) => {
        const regex = new RegExp(
          `!\$begin:math:display$image${index}\\$end:math:display$\$begin:math:text$blob:[^)]+\\$end:math:text$`,
          "g"
        );
        updatedMarkdown = updatedMarkdown.replace(regex, `![image](${url})`);
      });

      // 업데이트된 마크다운을 서버에 전송
      await axios.post(
        "https://data.swim.org/api/posts",
        {
          title: title,
          content: updatedMarkdown,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // JWT 토큰 추가
          },
        }
      );

      alert("게시가 완료되었습니다!");
    } catch (error) {
      console.error("Post failed:", error);
      alert(`Error: ${error.response?.data?.error || error.message}`);
    }
  };

  return (
    <div>
      <WriteFlex>
        <h2>게시글 작성</h2>

        {/* 타이틀 입력기 */}
        <textarea
          value={title}
          onChange={handleTitleChange}
          placeholder="Write your title here..."
        />

        {/* 마크다운 입력기 */}
        <textarea
          value={markdown}
          onChange={handleMarkdownChange}
          placeholder="Write your markdown here..."
          rows="10"
          cols="80"
        />
      </WriteFlex>

      {/* 이미지 선택 */}
      <div>
        <input
          type="file"
          accept="image/*"
          multiple
          onChange={handleImageSelect}
        />
      </div>

      {/* 미리보기 */}
      <h3>Preview</h3>
      <div className="preview">
        <MDEditor.Markdown source={markdown} />
      </div>

      {/* 게시 버튼 */}
      <button onClick={handlePost}>게시</button>
    </div>
  );
}

export default Write;
