import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  BibleBody,
  BibleChapterVerse,
  MarginBottom,
  QtContainer,
  QtSubtitle,
  QtTitle,
  QtWrap,
  MeditBody,
  VideoTitleWrap,
  VideoButton,
} from "../../styles/Qt.styled";
import Wallboard from "../../components/Wallboard";
import { getBibleRefJp } from "../../components/bibleIndex";
import ErrorPage from "../ErrorPage";
import { Helmet } from "react-helmet-async";
import { setLanguage } from "../../actions/locale";

const JpLife = () => {
  const [data, setData] = useState(null); // 데이터를 객체로 받음
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const selectedDate = useSelector((state) => state.calendar.selectedDate);
  const today = new Date();
  const initDay = moment(today).format("YYYY-MM-DD");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLanguage("ja"));
    const formattedDate = moment(selectedDate).format("YYYY-MM-DD");
    const fetchData = async () => {
      try {
        const response = await axios.get(
          // `/jplife/date=${
          `https://data.swim.org/jplife/date=${
            formattedDate ? formattedDate : initDay
          } 00:00:00`
        );
        setData(response.data[0]);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedDate]);

  if (loading) return <div>Loading...</div>;
  if (error) return <ErrorPage />;
  if (!data) return <ErrorPage />;

  const formatBibleText = (text) => {
    let formattedText = text.replace(
      /(\d+):/g,
      '<span class="chapter">$1</span>'
    );
    formattedText = formattedText.replace(
      /(\d+)/g,
      '</p><p><span class="verse">$1</span>'
    );
    formattedText = `<p>${formattedText}</p>`;
    return formattedText;
  };

  const formatLines = (text) => {
    if (!text) return "";
    return text.replace(/\r\n/g, "<br/>");
  };

  const vidurl = `http://cloudvod1.cgntv.net/_NewMP4/3/JMAQ${moment(
    selectedDate
  ).format("YYMMDD")}.h854x480.mp4`;

  const handleVidWinOpen = () => {
    window.open(vidurl);
  };

  return (
    <>
      <Helmet>
        <html lang="ja" />
        <title>SWIM QT リビングライフ</title>
      </Helmet>
      <Wallboard title={"リビングライフ"}>
        <QtWrap>
          <QtContainer>
            <QtTitle
              dangerouslySetInnerHTML={{
                __html: formatLines(data.title_UTF),
              }}
            ></QtTitle>

            <VideoTitleWrap>
              <h3 onClick={handleVidWinOpen} style={{ cursor: "pointer" }}>
                Video
              </h3>
              <VideoButton onClick={handleVidWinOpen}>▵</VideoButton>
            </VideoTitleWrap>
            <QtSubtitle>
              <p
                dangerouslySetInnerHTML={{
                  __html: formatLines(data.summary_UTF),
                }}
              ></p>
            </QtSubtitle>
            <BibleChapterVerse>{getBibleRefJp(data)}</BibleChapterVerse>
            <BibleBody
              dangerouslySetInnerHTML={{
                __html: formatBibleText(data.bible_text_UTF),
              }}
            ></BibleBody>

            <MeditBody>
              <h3>Comment</h3>
              <p
                dangerouslySetInnerHTML={{
                  __html: formatLines(data.comment_UTF),
                }}
              ></p>
            </MeditBody>

            <MeditBody>
              <h3>Pray</h3>
              <p
                dangerouslySetInnerHTML={{
                  __html: formatLines(data.pray_UTF),
                }}
              ></p>
            </MeditBody>
            <MarginBottom />
          </QtContainer>
        </QtWrap>
      </Wallboard>
    </>
  );
};

export default JpLife;
