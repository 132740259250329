import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Img from "../img/404.png";
import styled from "styled-components";

const ErrWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  padding: 0 16px;
  h1 {
    color: rgb(29 114 235);
    margin-block-end: 0;
  }

  span {
    font-size: 2em;
    margin-right: 8px;
  }
  img {
    width: 100%;
    height: auto;
  }
`;

const ErrImg = styled(Img)`
  width: 100%;
  height: auto;
`;

const ErrorPage = () => {
  const [counter, setCounter] = useState(5);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setInterval(() => {
      setCounter((prevCounter) => {
        if (prevCounter <= 1) {
          navigate("/");
          return 0;
        }
        return prevCounter - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [navigate]);

  return (
    <ErrWrap>
      <h1>앗! 페이지가 없네요..!</h1>
      <h1>
        <span>{counter}</span>초 후에 자동으로 돌아갑니다.
      </h1>
      <img src={Img} />
    </ErrWrap>
  );
};

export default ErrorPage;
