import React from "react";
import styled from "styled-components";
import { ReactComponent as LogoImg } from "../img/logo_swim.svg";
import { NavLink } from "react-router-dom";
import { menuDataFooter } from "../menudata";
import GlobalDonate from "./donate";
import FloatingButton from "./FloatingButton";
import { useSelector } from "react-redux";

const FooterWrap = styled.div`
  background-color: rgb(29 114 235);
`;

const FooterBg = styled.div`
  background-color: ${({ color }) => color};
  width: 100%;
  padding-top: 60px;
  padding-bottom: 32px;
`;

const FooterContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: 767px) {
    max-width: 430px;
  }
`;

const FooterMenu = styled.div`
  grid-column-gap: 32px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
  @media screen and (max-width: 1279px) {
    margin: 0 16px;
  }
  @media screen and (max-width: 767px) {
    grid-row-gap: 40px;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 8px;
  }
`;

const FooterMenuItems = styled.div`
  flex-direction: column;
  align-items: flex-start;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  gap: 8px;
  grid-area: span 1 / span 1 / span 1 / span 1;
`;

const FooterMenuTitle = styled.div`
  font-weight: 600;
  color: white;
`;

const FooterMenuItem = styled(NavLink)`
  color: #eee;
  text-decoration: none;
`;

const Logo = styled(LogoImg)`
  width: 100px;
  .d {
    fill: #fff;
  }
  @media screen and (max-width: 767px) {
    grid-area: span 1 / span 2 / span 1 / span 2;
  }
`;

const FooterEndWrap = styled.div`
  margin-top: 45px;
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 1279px) {
    margin: 0 16px;
    margin-top: 45px;
  }
`;

const FooterCopyright = styled.p`
  color: #ddd;
  font-size: 0.9em;
`;

const FooterChannels = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const FooterMenuList = () => {
  return (
    <FooterMenu>
      <Logo />
      {menuDataFooter.map((menu, index) => (
        <FooterMenuItemList key={index} title={menu.title} links={menu.links} />
      ))}
    </FooterMenu>
  );
};

const FooterMenuItemList = ({ title, links }) => {
  return (
    <FooterMenuItems>
      {title ? <FooterMenuTitle>{title}</FooterMenuTitle> : ""}
      {links.map((link, index) => (
        <FooterMenuItem key={index} to={link.path}>
          {link.name}
        </FooterMenuItem>
      ))}
    </FooterMenuItems>
  );
};

const Footer = () => {
  const scroll = useSelector((state) => state.scroll.scroll);
  return (
    <FooterWrap scroll={scroll}>
      <FooterBg color={" #f5f6ff"}>
        <GlobalDonate />
      </FooterBg>
      <FooterBg color={"rgb(29 114 235)"}>
        <FooterContainer>
          <FooterMenuList />
        </FooterContainer>
        <FooterContainer>
          <FooterEndWrap>
            <FooterCopyright>©SWIM.org</FooterCopyright>
            <FooterChannels></FooterChannels>
          </FooterEndWrap>
        </FooterContainer>
      </FooterBg>
      <FloatingButton />
    </FooterWrap>
  );
};

export default Footer;
