import React from "react";
import { useNavigate } from "react-router-dom";
import { ItemContainer, ItemName } from "../pages/QtList";
import { ItemBtnLink, ItemFlex, ItemGrid } from "./qtLinkCard";

const QtChildrenCard = () => {
  const navigate = useNavigate();
  const handleLikejesus = () => {
    navigate("/qt/likejesus");
  };
  const handleDawn = () => {
    navigate("/qt/dawn");
  };

  return (
    <ItemContainer>
      <ItemFlex>
        <ItemName color="#f376c0">어린이 / 청소년</ItemName>
        <ItemGrid>
          <ItemBtnLink onClick={handleLikejesus}>예수님이 좋아요</ItemBtnLink>
          <ItemBtnLink onClick={handleDawn}>새벽나라</ItemBtnLink>
        </ItemGrid>
      </ItemFlex>
    </ItemContainer>
  );
};

export default QtChildrenCard;
