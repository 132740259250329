import { SELECT_DATE } from "../actions/calendar";

const initialState = {
  selectedDate: new Date(),
};

const dateReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_DATE:
      return { ...state, selectedDate: action.payload };
    default:
      return state;
  }
};

export default dateReducer;
