import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  BibleBody,
  BibleChapterVerse,
  MarginBottom,
  QtContainer,
  QtSubtitle,
  QtTitle,
  QtWrap,
  MeditBody,
} from "../../styles/Qt.styled";
import Wallboard from "../../components/Wallboard";
import { getBibleReference } from "../../components/bibleIndex";
import ErrorPage from "../ErrorPage";
import { setLanguage } from "../../actions/locale";

const Dailybible = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const selectedDate = useSelector((state) => state.calendar.selectedDate);
  const today = new Date();
  const initDay = moment(today).format("YYYY-MM-DD");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLanguage("ko"));
    const formattedDate = moment(selectedDate).format("YYYY-MM-DD");
    const fetchData = async () => {
      try {
        const response = await axios.get(
          //   `/dailybible/date=${formattedDate ? formattedDate : initDay} 00:00:00`
          `https://data.swim.org/dailybible/date=${
            formattedDate ? formattedDate : initDay
          } 00:00:00`
        );
        setData(response.data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedDate]);

  if (loading) return <div>Loading...</div>;
  if (error) return <ErrorPage />;
  if (!data) return <ErrorPage />;

  const formatBibleText = (text) => {
    // 숫자 뒤에 콜론(:)이 있는 경우 span 태그로 감싸고 콜론을 제거
    let formattedText = text.replace(
      /(\d+):/g,
      '<span class="chapter">$1</span>'
    );

    // 숫자 앞에서 줄바꿈을 하고, 숫자를 <span> 태그로 감싸기
    // 숫자가 있는 줄을 p 태그로 감싸기
    formattedText = formattedText.replace(
      /(\d+)/g,
      '</p><p><span class="verse">$1</span>'
    );

    // 전체 텍스트를 감싸는 최상위 p 태그 추가
    formattedText = `<p>${formattedText}</p>`;

    return formattedText;
  };

  const formatLines = (text) => {
    if (!text) return "";
    return text.replace(/\r\n/g, "<br/>");
  };

  return (
    <Wallboard title={"매일성경"}>
      <QtWrap>
        <QtContainer>
          {data.map((item, index) => (
            <div key={index}>
              <QtTitle
                dangerouslySetInnerHTML={{
                  __html: formatLines(item.title_UTF),
                }}
              ></QtTitle>
              <QtSubtitle>
                <h3>중심내용</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: formatLines(item.point_title_UTF),
                  }}
                ></p>
              </QtSubtitle>
              <BibleChapterVerse>{getBibleReference(item)}</BibleChapterVerse>
              <BibleBody
                dangerouslySetInnerHTML={{
                  __html: formatBibleText(item.bible_text_UTF),
                }}
              ></BibleBody>
              {item.today_qt_UTF ? (
                <MeditBody>
                  <h3>하나님은 이런 분</h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: formatLines(item.today_qt_UTF),
                    }}
                  ></p>
                </MeditBody>
              ) : (
                ""
              )}
              {item.today_qt2_UTF ? (
                <MeditBody>
                  <h3>내게 주신 교훈</h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: formatLines(item.today_qt2_UTF),
                    }}
                  ></p>
                </MeditBody>
              ) : (
                ""
              )}
              <MeditBody>
                <h3>기도</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: formatLines(item.today_pray_UTF),
                  }}
                ></p>
              </MeditBody>
              <MarginBottom />
            </div>
          ))}
        </QtContainer>
      </QtWrap>
    </Wallboard>
  );
};

export default Dailybible;
