import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { emailsubmit } from "../actions/subscribe";
import styled from "styled-components";
import Img from "../img/email.png";

const EmailImg = styled.img`
  max-width: 100%;
  height: auto;
`;

const EmailFormWrap = styled.div`
  margin-bottom: 80px;
  max-width: 380px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  h1 {
    color: rgb(29 114 235);
  }
  p {
    text-align: center;
    color: rgb(85, 85, 85);
  }
  h5 {
    margin-block-start: 0;
    color: rgb(29 114 235);
  }
`;

const AccFlex = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  margin: 24px 0;
`;

const AccInput = styled.input`
  border-radius: 8px;
  border: 1px solid rgba(200, 200, 200, 0.7);
  height: 30px;
  font-weight: 600;
  font-size: 1em;
  color: #777;
  background-color: transparent;
  padding-left: 8px;
  &::placeholder {
    color: #aaa;
    font-weight: 300;
  }
  &:focus {
    border: 1px solid rgb(29 114 235);
    outline: none;
    box-shadow: 0px 0px 5px 1px rgba(29, 114, 235, 0.2);
  }
`;

const SubmitBtn = styled.button`
  border-radius: 8px;
  border: none;
  height: 30px;
  width: 80px;
  text-align: center;
  font-weight: 600;
  color: white;
  background-color: rgb(29 114 235);
  cursor: pointer;
  &:hover {
    background-color: #81adeb;
  }
`;

const Subscribe = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const isSubscriber = useSelector((state) => state.email.subscribe);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(isSubscriber);
    if (localStorage.getItem("email") || isSubscriber) {
      alert("이미 신청하셨습니다.");
      navigate("/");
    }
  }, []);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const validateEmail = (email) => {
    // 이메일 형식 검증 정규 표현식
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    localStorage.setItem("email", email);

    // if (!validateEmail(email)) {
    //   setMessage("유효한 이메일 형식이 아닙니다.");
    //   return;
    // }

    try {
      const response = await axios.get(
        `https://qt.swim.org/sendCheckMail.php?${email}`
      );
      if (response.status === 200) {
        alert("이메일이 성공적으로 전송되었습니다.");
        setMessage("");
        dispatch(emailsubmit(true));
        navigate("/");
      }
    } catch (error) {
      alert("신청서를 메일로 보내드렸어요!");
      setMessage("");
      dispatch(emailsubmit(true));
      navigate("/");
    }
  };

  return (
    <EmailFormWrap>
      <h1>이메일로 매일 QT 받아보기</h1>
      <EmailImg src={Img} />
      <p>생명의 삶, Living Life, 오늘의 양식 등</p>
      <p>QT를 선택하여 구독하실 수 있습니다.</p>
      <form onSubmit={handleSubmit}>
        <AccFlex>
          <AccInput
            type="email"
            value={email}
            onChange={handleEmailChange}
            placeholder="you@example.com"
            pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
            title="유효한 이메일 주소를 입력하세요"
            required
          />
          <SubmitBtn type="submit">신청하기</SubmitBtn>
        </AccFlex>
      </form>
      {message && <p>{message}</p>}
      <h5>* 메일에 신청서가 들어있습니다.</h5>
    </EmailFormWrap>
  );
};

export default Subscribe;
