import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const TagNavWrap = styled.div`
  margin: 8px 0;
  display: flex;
  justify-content: center;
`;

const TagNavContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  height: 40px;
  gap: 8px;
  @media screen and (max-width: 800px) {
    height: 28px;
  }
`;

const TagNavItem = styled(NavLink)`
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.5);
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  color: ${({ color }) => color};
  min-width: 90px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  font-size: 0.8em;

  &:hover {
    background-color: rgb(255, 255, 255);
  }
  @media screen and (max-width: 800px) {
    font-size: 0.7em;
    padding: 0 12px;
  }
`;

const TagNav = () => {
  return (
    <TagNavWrap>
      <TagNavContainer>
        <TagNavItem to={"/subscribe"} color={"#555"}>
          메일링 정보변경 및 취소
        </TagNavItem>
        <TagNavItem to={"/subscribe"} color={"#134d9e"}>
          QT메일링 신청
        </TagNavItem>
      </TagNavContainer>
    </TagNavWrap>
  );
};

export default TagNav;
