import React from "react";
import styled, { keyframes } from "styled-components";
import { handlerDonation } from "./GnbPc";

const bounce = keyframes`
  from, to { transform: scale(1, 1); }
  2% { transform: scale(0.9, 1.1); }
  4% { transform: scale(1.1, 0.9); }
  6% { transform: scale(0.95, 1.05); }
  8% { transform: scale(1, 1); }
`;

const FltBtnWrap = styled.div`
  position: fixed;
  z-index: 900;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  border: none;
  border-radius: 100px;
  width: 45px;
  height: 45px;
  background-color: #eb331c;
  right: 12px;
  bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${bounce} 5s infinite;
  &:hover > div {
    display: flex;
  }
`;

const Tooltip = styled.div`
  position: absolute;
  width: 100px;
  height: 30px;
  background-color: white;
  border-radius: 4px;
  /* top: -35px; */
  right: 50px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  font-weight: 600;
  font-size: 0.9em;
  color: #eb331c;
  display: none;
  justify-content: center;
  align-items: center;
`;

const FltBtn = styled.button`
  font-size: 1.2em;
  font-weight: 600px;
  border: none;
  color: white;
  background-color: transparent;
  border-radius: 100px;
  font-family: "Noto Sans KR";
  cursor: pointer;
`;

const FloatingButton = () => {
  return (
    <FltBtnWrap>
      <FltBtn onClick={handlerDonation}>♥</FltBtn>
      <Tooltip>후원해요!</Tooltip>
    </FltBtnWrap>
  );
};

export default FloatingButton;
