import axios from "axios";
import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import ErrorPage from "../pages/ErrorPage";

const PageContainer = styled.div`
  /* margin-top: 120px; */
  margin: 80px 0;
  max-width: 600px;
  @media screen and (max-width: 767px) {
    padding: 0 16px;
  }
`;

const Block = styled.div`
  /* margin-bottom: 20px; */
  width: 100%;
`;

const StyledImage = styled.img`
  /* max-width: 600px; */
  width: 100%;
  height: auto;
`;

const MarkdownP = styled(ReactMarkdown)`
  max-width: 600px;
  h1,
  h3 {
    color: rgb(29 114 235);
    margin-top: 50px;
    margin-block-end: 0;
  }
  h5 {
    color: rgb(29 114 235);
    font-size: 1.1em;
    font-weight: 600;
  }

  p {
    /* max-width: 600px; */
    width: 100%;
    color: #555;
  }
`;

const renderers = {
  img: ({ node, ...props }) => <StyledImage {...props} />,
};

const Page = () => {
  const { id } = useParams();
  const [page, setPage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios
      .get(`https://data.swim.org/api/page/${id}`)
      .then((response) => {
        setPage(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [id]);

  if (loading) return <PageContainer>Loading...</PageContainer>;
  if (error) return <ErrorPage />; // 에러 발생 시 ErrorPage 컴포넌트 렌더링
  if (!page) return <PageContainer>Post not found</PageContainer>;

  const blocks = page.content
    .trim()
    .split("\n")
    .filter((block) => block.trim() !== "");

  return (
    <PageContainer>
      {blocks.map((block, index) => (
        <Block key={index}>
          <MarkdownP components={renderers}>{block}</MarkdownP>
        </Block>
      ))}
    </PageContainer>
  );
};

export default Page;
