import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { refreshToken } from "../actions/auth";

const TokenRefresh = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(refreshToken());
    }, 14 * 60 * 1000); // 14분마다 토큰 갱신

    return () => clearInterval(interval);
  }, [dispatch]);

  return null;
};

export default TokenRefresh;
