import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  BibleBody,
  BibleChapterVerse,
  MarginBottom,
  QtContainer,
  QtSubtitle,
  QtTitle,
  QtWrap,
  MeditBody,
  VideoTitleWrap,
  VideoButton,
} from "../../styles/Qt.styled";
import Wallboard from "../../components/Wallboard";
import { getBibleReference } from "../../components/bibleIndex";
import ErrorPage from "../ErrorPage";
import { setLanguage } from "../../actions/locale";

const Life = () => {
  const [data, setData] = useState(null); // 데이터를 객체로 받음
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const selectedDate = useSelector((state) => state.calendar.selectedDate);
  const today = new Date();
  const initDay = moment(today).format("YYYY-MM-DD");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLanguage("ko"));
    const formattedDate = moment(selectedDate).format("YYYY-MM-DD");
    const fetchData = async () => {
      try {
        const response = await axios.get(
          // `/life/date=${
          `https://data.swim.org/life/date=${
            formattedDate ? formattedDate : initDay
          } 00:00:00`
        );
        setData(response.data[0]);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedDate]);

  if (loading) return <div>Loading...</div>;
  if (error) return <ErrorPage />;
  if (!data) return <ErrorPage />;

  const formatBibleText = (text) => {
    let formattedText = text.replace(
      /(\d+):/g,
      '<span class="chapter">$1</span>'
    );
    formattedText = formattedText.replace(
      /(\d+)/g,
      '</p><p><span class="verse">$1</span>'
    );
    formattedText = `<p>${formattedText}</p>`;
    return formattedText;
  };

  const formatLines = (text) => {
    if (!text) return "";
    return text.replace(/\r\n/g, "<br/>");
  };

  const extractContent = (text) => {
    if (!text) return { point: "", body: "" }; // text가 없을 경우 빈 값을 반환
    const [pointContent, ...bodyContentArray] = text.split("</b>");
    let point = pointContent.replace(/<b>/g, "").trim();
    point = point.replace(/\[.*?\]/g, "").trim();
    const body = bodyContentArray.join("").trim();
    return {
      point,
      body,
    };
  };

  const vidurl = `http://cloudvod1.cgntv.net/_NewMP4/1/MAQT${moment(
    selectedDate
  ).format("YYMMDD")}.h854x480.mp4`;

  const handleVidWinOpen = () => {
    window.location.href = vidurl;
  };

  return (
    <Wallboard title={"생명의 삶"}>
      <QtWrap>
        <QtContainer>
          <QtTitle
            dangerouslySetInnerHTML={{
              __html: formatLines(data.title_UTF),
            }}
          ></QtTitle>

          {/* 삼항연산자 조건문으로 편의성 제공하려고 했으나, cgn자료 보안문제로 안돼서 안함
            <VideoContainer display={opened}>
              <VideoTitleWrap>
                <h3 onClick={handleVideoOpen}>영상큐티 보기</h3>
                <VideoButton rotate={opened} onClick={handleVideoOpen}>
                  ▵
                </VideoButton>
              </VideoTitleWrap>
              <VideoWrap display={opened}>
                <VideoPlayer
                  preload="true"
                  controls
                  autoplay
                  src={vidurl}
                ></VideoPlayer>
              </VideoWrap>
            </VideoContainer> */}

          <VideoTitleWrap>
            <h3 onClick={handleVidWinOpen} style={{ cursor: "pointer" }}>
              영상큐티 보기
            </h3>
            <VideoButton onClick={handleVidWinOpen}>▵</VideoButton>
          </VideoTitleWrap>
          <QtSubtitle>
            <h3>말씀 요약</h3>
            <p
              dangerouslySetInnerHTML={{
                __html: extractContent(data.bible_text_UTF).point,
              }}
            ></p>
          </QtSubtitle>
          <BibleChapterVerse>{getBibleReference(data)}</BibleChapterVerse>
          <BibleBody
            dangerouslySetInnerHTML={{
              __html: formatBibleText(extractContent(data.bible_text_UTF).body),
            }}
          ></BibleBody>
          {data.deep_medit_UTF && (
            <MeditBody>
              <h3>더 깊은 묵상</h3>
              <p
                dangerouslySetInnerHTML={{
                  __html: formatLines(data.deep_medit_UTF),
                }}
              ></p>
            </MeditBody>
          )}
          <MeditBody>
            <h3>오늘의 기도</h3>
            <p
              dangerouslySetInnerHTML={{
                __html: formatLines(data.today_pray_UTF),
              }}
            ></p>
          </MeditBody>
          <MarginBottom />
        </QtContainer>
      </QtWrap>
    </Wallboard>
  );
};

export default Life;
