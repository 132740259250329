import React from "react";
import styled from "styled-components";
// import { data } from "./dummy";

const PostContainer = styled.div`
  max-width: 1200px;
  margin: auto;
  margin-bottom: 60px;
`;
const PostCardGrid = styled.div`
  display: grid;
  width: 100%;
  gap: 12px;
  grid-template-columns: 1fr 1fr;
  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

const CardItemWrap = styled.div`
  display: flex;
  flex-direction: row;
  height: 125px;
  border: 1px solid #aaa;
  border-radius: 8px;
  min-width: 320px;
`;
const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 12px;
  flex-grow: 1;
`;

const ItemTagsWrap = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;
const Tag = styled.div`
  border-radius: 100px;
  height: 16px;
  border: 1px solid #aaa;
  font-size: 0.7em;
  padding: 4px 12px;
`;

const ItemTitle = styled.div`
  font-size: 1em;
  font-weight: 600;
  margin-block-start: 8px;
  margin-block-end: 8px;
  padding-left: 8px;
`;

const ItemWriter = styled.div`
  font-size: 0.8em;
  color: #777;
  margin-block-start: 4px;
  margin-block-end: 4px;
  padding-left: 8px;
`;

const Thumbnail = styled.img`
  margin: 12px;
  border-radius: 4px;
  width: 100px;
  height: 100px;
  object-fit: cover;
  flex-shrink: 0;
`;

const Post = ({ data = [] }) => {
  // const Post = () => {
  return (
    <PostContainer>
      <PostCardGrid>
        {data?.map((post) => (
          <CardItemWrap key={post.id}>
            <ItemContainer>
              <ItemTagsWrap>
                {post.tags[0] ? (
                  post.tags.map((tag, index) => <Tag key={index}>{tag}</Tag>)
                ) : (
                  <Tag>태그없음</Tag>
                )}
              </ItemTagsWrap>
              <ItemTitle>{post.title}</ItemTitle>
              <ItemWriter>by {post.userName}</ItemWriter>
            </ItemContainer>
            {post.imgUrl ? (
              <Thumbnail src={post.imgUrl} alt={post.title} />
            ) : (
              <></>
            )}
          </CardItemWrap>
        ))}
      </PostCardGrid>
    </PostContainer>
  );
};

export default Post;
