import React from "react";
import styled, { keyframes } from "styled-components";

// Root 스타일 정의
const Root = styled.div`
  --color-text: navy;
  --color-bg: white;
  --color-bg-accent: #fff;
  --size: clamp(10rem, 1rem + 30vmin, 30rem);
  --gap: calc(var(--size) / 25);
  --duration: 50s;
  --scroll-start: 0;
  --scroll-end: calc(-100% - var(--gap));
`;

// 마퀴 애니메이션 정의
const scrollX = keyframes`
  from {
    transform: translateX(var(--scroll-start));
  }
  to {
    transform: translateX(var(--scroll-end));
  }
`;

// 마퀴 그룹 스타일 정의
const MarqueeGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--gap);
  min-width: ${({ numImages }) =>
    `calc(${numImages} * var(--size) + (${numImages} - 1) * var(--gap))`};
  animation: ${scrollX} var(--duration) linear infinite;
`;

// 마퀴 스타일 정의
const Marquee = styled.div`
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);
  mask-image: linear-gradient(
    to right,
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 1) 20%,
    hsl(0 0% 0% / 1) 80%,
    hsl(0 0% 0% / 0)
  );
`;

// 이미지 스타일 정의
const ImageWrapper = styled.div`
  display: grid;
  place-items: center;
  width: var(--size);
  background: var(--color-bg-accent);
  aspect-ratio: 3/1;
  padding: calc(var(--size) / 20);
  border-radius: 16px;
  img {
    max-width: 140px;
    height: auto;
    max-height: 9vw;
    object-fit: contain;
  }
`;

// 부모 Wrapper 스타일 정의
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: auto;
  max-width: 90vw;
`;

const ImgSlide = () => {
  const images = [
    { url: "https://www.swim.org/testhtml/img/1_sum.png", alt: "Image 1" },
    { url: "https://www.swim.org/testhtml/img/2_drn.png", alt: "Image 2" },
    { url: "https://www.swim.org/testhtml/img/3_odb.png", alt: "Image 3" },
    { url: "https://www.swim.org/testhtml/img/4_sfc.png", alt: "Image 4" },
    { url: "https://www.swim.org/testhtml/img/5_prcp.png", alt: "Image 5" },
    { url: "https://www.swim.org/testhtml/img/6_esf.png", alt: "Image 6" },
    { url: "https://www.swim.org/testhtml/img/7_kpck.png", alt: "Image 7" },
    { url: "https://www.swim.org/testhtml/img/8_ivp.png", alt: "Image 8" },
  ];

  return (
    <Root>
      <Wrapper>
        <Marquee>
          <MarqueeGroup numImages={images.length}>
            {images.map((image, index) => (
              <ImageWrapper key={index}>
                <img src={image.url} alt={image.alt || `image-${index}`} />
              </ImageWrapper>
            ))}
          </MarqueeGroup>

          <MarqueeGroup aria-hidden="true" numImages={images.length}>
            {images.map((image, index) => (
              <ImageWrapper key={index + images.length}>
                <img src={image.url} alt={image.alt || `image-${index}`} />
              </ImageWrapper>
            ))}
          </MarqueeGroup>
        </Marquee>
      </Wrapper>
    </Root>
  );
};

export default ImgSlide;
