import React from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { modalClose } from "../actions/modal"; // 모달을 닫는 액션

const ModalBg = styled.div`
  width: 100%;
  height: 100%;
  /* backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px); */
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  display: ${({ display }) => (display ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  z-index: 2000;
`;

const Modal = ({ children }) => {
  const isVisible = useSelector((state) => state.modal.pop);
  const dispatch = useDispatch();

  const handleBackgroundClick = (event) => {
    // 이벤트 타겟이 ModalBg라면(배경 클릭), 모달을 닫음
    if (event.target === event.currentTarget) {
      dispatch(modalClose());
    }
  };

  return (
    <ModalBg display={isVisible} onClick={handleBackgroundClick}>
      {children}
    </ModalBg>
  );
};

export default Modal;
