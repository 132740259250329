import axios from "axios";
import React, { useEffect, useState } from "react";
import Post from "./Post";

// axios.defaults.withCredentials = true;

const Posts = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const token = localStorage.getItem("token"); // JWT를 로컬 스토리지에서 가져오기
        const response = await axios.get("https://data.swim.org/api/posts", {
          // headers: {
          //   Authorization: `Bearer ${token}`,
          // },
        });
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  console.log(data);

  return <div>{data && <Post data={data} />}</div>;
};

export default Posts;
