import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  BibleBody,
  BibleChapterVerse,
  MarginBottom,
  QtContainer,
  QtTitle,
  QtWrap,
} from "../../styles/Qt.styled";
import Wallboard from "../../components/Wallboard";
import { getBibleRefSpur } from "../../components/bibleIndex";
import ErrorPage from "../ErrorPage";
import { setLanguage } from "../../actions/locale";

const Spurgeon = () => {
  const [data, setData] = useState(null); // 데이터를 객체로 받음
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const selectedDate = useSelector((state) => state.calendar.selectedDate);
  const isVisible = useSelector((state) => state.modal.pop);
  const today = new Date();
  const initDay = moment(today).format("YYYY-MM-DD");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLanguage("ko"));
    const formattedDate = moment(selectedDate).format("YYYY-MM-DD");
    const fetchData = async () => {
      try {
        const response = await axios.get(
          // `/life/date=${
          `https://data.swim.org/spurgeon/date=${
            formattedDate ? formattedDate : initDay
          } 00:00:00`
        );
        setData(response.data[0]);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedDate]);

  if (loading) return <div>Loading...</div>;
  if (error) return <ErrorPage />;
  if (!data) return <ErrorPage />;

  const formatLines = (text) => {
    if (!text) return "";
    return text.replace(/\. /g, ".<br/>");
  };

  return (
    <Wallboard title={"스펄전의 아침묵상"} scroll={isVisible}>
      <QtWrap>
        <QtContainer>
          <QtTitle
            dangerouslySetInnerHTML={{
              __html: data.bible_text_UTF,
            }}
          ></QtTitle>
          <BibleChapterVerse>{getBibleRefSpur(data)}</BibleChapterVerse>
          <BibleBody
            dangerouslySetInnerHTML={{
              __html: formatLines(data.message_text_UTF),
            }}
          ></BibleBody>

          <MarginBottom />
        </QtContainer>
      </QtWrap>
    </Wallboard>
  );
};

export default Spurgeon;
