import { MODAL_CLOSE, MODAL_OPEN } from "../actions/modal";

const initialState = {
  pop: false,
  type: "",
};

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case MODAL_OPEN:
      return { ...state, pop: true, type: action.payload };
    case MODAL_CLOSE:
      return { ...state, pop: false };
    default:
      return state;
  }
};

export default modalReducer;
