import axios from "axios";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";
export const REFRESH_TOKEN = "REFRESH_TOKEN";

export const loginSuccess = (token) => ({
  type: LOGIN_SUCCESS,
  payload: token,
});

export const logout = () => ({
  type: LOGOUT,
});

export const refreshToken = () => async (dispatch) => {
  try {
    const response = await axios.post("https://data.swim.org/api/refresh");
    const { token } = response.data;
    localStorage.setItem("token", token);
    dispatch({
      type: REFRESH_TOKEN,
      payload: token,
    });
  } catch (error) {
    dispatch(logout());
    localStorage.setItem("token", "");
  }
};
