import React from "react";
import styled from "styled-components";

const DonateWrap = styled.div`
  position: relative;
  width: 100%;
  margin-top: 80px;
  z-index: -1;
`;

const DonateContainer = styled.div`
  max-width: 600px;
  margin: auto;
`;

const DonateContents = styled.object`
  width: 100%;
  height: 2600px;
`;

const Donate = () => {
  return (
    <DonateWrap>
      <DonateContainer>
        <DonateContents data="https://www.ihappynanum.com/Nanum/nanum/banner/bridge/93XSS2KFDL" />
      </DonateContainer>
    </DonateWrap>
  );
};

export default Donate;
