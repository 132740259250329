import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { NavLink, useNavigate } from "react-router-dom";
import { ReactComponent as LogoImg } from "../img/logo_swim.svg";
import { menuData } from "../menudata";
import Button from "./button";
import GnbMobile from "./GnbMobile";
import GnbPc from "./GnbPc";
import { useSelector } from "react-redux";

const GnbWrap = styled.nav`
  width: 100%;
  top: -100px;
  position: fixed;
  display: flex;
  flex-direction: column;
  margin: 0;
  z-index: 1000;
`;

const PwaBox = styled.div`
  height: 100px;
`;

const GnbContainer = styled.nav`
  width: 100%;
  height: ${({ trigger }) => (trigger ? "40px" : "80px")};
  background-color: ${({ trigger }) =>
    trigger ? "rgb(29 114 235)" : "rgb(255 255 255)"};
  transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
`;

const GnbContentWrap = styled.div`
  height: inherit;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  margin: auto;
`;

const Logo = styled(LogoImg)`
  cursor: pointer;
  height: auto;
  max-width: 75px;
  margin: 4px;
  margin-left: 16px;
  .d {
    fill: ${({ trigger }) =>
      trigger ? "rgb(255 255 255)" : " rgb(29 114 235)"};
  }
  transition: fill 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
`;

const ButtonPadding = styled.div`
  margin-right: 16px;
  display: flex;
  justify-content: center;
`;

const Gnb = () => {
  const [scrTrigger, setScrTrigger] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const { isAuthenticated } = useSelector((state) => state.auth);

  const navigate = useNavigate();

  const homeHandler = () => {
    navigate("/");
  };

  const loginHandler = () => {
    navigate("/login");
  };

  const myPostHandler = () => {
    navigate("/my/posts");
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const y = window.scrollY;
      if (y > 20) setScrTrigger(true);
      else setScrTrigger(false);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const gColor = () => {
    if (scrTrigger) return "#fff";
    else return "#000";
  };

  return (
    <GnbWrap>
      <PwaBox />
      <GnbContainer trigger={scrTrigger}>
        <GnbContentWrap>
          <Logo trigger={scrTrigger} onClick={homeHandler} />
          {isMobile ? (
            <GnbMobile trigger={scrTrigger} color={gColor} />
          ) : (
            <>
              <GnbPc trigger={scrTrigger} />
              {isAuthenticated ? (
                <ButtonPadding>
                  <Button title={"My"} color={gColor} link={myPostHandler} />
                </ButtonPadding>
              ) : (
                <ButtonPadding>
                  <Button title={"Log in"} color={gColor} link={loginHandler} />
                </ButtonPadding>
              )}
            </>
          )}
        </GnbContentWrap>
      </GnbContainer>
    </GnbWrap>
  );
};

export default Gnb;
