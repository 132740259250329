import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ItemContainer, ItemName } from "../pages/QtList";
import { ItemBtnLink, ItemFlex, ItemGrid } from "./qtLinkCard";

const QtLanguageCard = () => {
  const navigate = useNavigate();
  const handleLiving = () => {
    navigate("/qt/living");
  };
  const handleJapanLife = () => {
    navigate("/qt/jplife");
  };
  const handleDailybibleEng = () => {
    navigate("/qt/dailybibleeng");
  };
  const handleArabicLife = () => {
    navigate("/qt/arlife");
  };

  return (
    <ItemContainer>
      <ItemFlex>
        <ItemName color="#3fa8f4">Global QT</ItemName>
        <ItemGrid>
          <ItemBtnLink onClick={handleLiving}>living life</ItemBtnLink>
          <ItemBtnLink onClick={handleJapanLife}>リビングライフ</ItemBtnLink>
          <ItemBtnLink onClick={handleDailybibleEng}>Daily Bible</ItemBtnLink>
          <ItemBtnLink onClick={handleArabicLife}>
            Living Life Arabic
          </ItemBtnLink>
        </ItemGrid>
      </ItemFlex>
    </ItemContainer>
  );
};

export default QtLanguageCard;
