import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

const DailyWordWrap = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 800px) {
    width: 390px;
  }
  @media screen and (max-width: 410px) {
    width: 100%;
    max-width: 390px;
  }
`;

const DailyWordTitle = styled.p`
  font-size: 1em;
  margin: 0 16px;
  color: white;
  font-family: "Pretendard-Regular";
`;

const DailyWordContainer = styled.div`
  width: 100%;
  height: auto;
  min-height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WordParagraph = styled.div`
  max-width: 800px;

  p {
    width: 100%;
    font-size: 2em;
    line-height: 2em;
    color: white;
    font-family: "NanumSquareNeoExtraBold";
  }
  h5 {
    width: 100%;
    font-size: 1em;
    color: white;
    font-family: "Pretendard-Regular";
    font-weight: 300;
    text-align: right;
  }

  @media screen and (max-width: 800px) {
    margin: 0 16px;
    max-width: 400px;
  }
`;

const formatLines = (text) => {
  if (!text) return "";
  // 텍스트를 \r\n\r\n 으로 분리
  const lines = text.split(/\r\n\r\n/);
  const firstLine = lines.shift(); // 첫 번째 줄을 가져옴
  const lastLine = lines.join(" "); // 남은 텍스트는 이어 붙임

  // 첫 번째 줄을 <p>로, 마지막 줄을 <span>으로 감싸서 반환
  return `<p>${firstLine}</p><h5 class="book">${lastLine}</h5>`;
};

const Dailyword = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const today = moment(new Date()).format("YYYY-MM-DD");

  useEffect(() => {
    const formattedDate = moment(new Date()).format("YYYY-MM-DD");
    const fetchData = async () => {
      try {
        const response = await axios.get(
          // `/word/date=${formattedDate} 00:00:00`
          `https://data.swim.org/word/date=${formattedDate} 00:00:00`
        );
        setData(response.data[0]);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <DailyWordWrap>
      <DailyWordTitle>[ {today} ] 오늘의 말씀 한줄</DailyWordTitle>
      <DailyWordContainer>
        <WordParagraph
          dangerouslySetInnerHTML={{
            __html: formatLines(data.kor_text_UTF),
          }}
        />
      </DailyWordContainer>
    </DailyWordWrap>
  );
};

export default Dailyword;
