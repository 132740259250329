import React, { useState, useEffect } from "react";
import { menuData } from "../menudata";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { allowScroll, disableScroll } from "../reducer/scrollReducer";
import Button from "./button";
import { handlerDonation } from "./GnbPc";
import styled, { keyframes } from "styled-components";

const HamburgerIconWrap = styled.div`
  width: 45px;
  height: 100%;
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HamburgerIcon = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-around;
  width: 24px;
  height: 24px;
`;

const HamburgerIconG = styled.div`
  width: 24px;
  height: 2px;
  background-color: ${({ color }) => color};
  border-radius: 10px;
  transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  position: relative;
  transform-origin: 1px;
`;

const HamburgerIconTop = styled(HamburgerIconG)`
  transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
`;

const HamburgerIconMid = styled(HamburgerIconG)`
  opacity: ${({ open }) => (open ? "0" : "1")};
`;

const HamburgerIconBottom = styled(HamburgerIconG)`
  transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
`;

const fadeIn = keyframes`
  from {
opacity: 0;
  }
  to {
opacity: 1;
  }
`;

const listIn = keyframes`
  from {
    transform: scale(80%) translateY(-52px);
    opacity: 0.5;
  }
  to {
    transform: scale(100%) translateY(0);
    opacity: 1;
  }
`;

const titleIn = keyframes`
  from {
    transform: scale(80%);
  }
  to {
    transform: scale(100%);
  }
`;

const MobileMenuWrap = styled.div`
  display: ${({ open }) => (open ? "flex" : "none")};
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;
  top: ${({ trigger }) => (trigger ? "40px" : "80px")};
`;

const MobileMenu = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  /* max-height: 60vh;
    margin: 0 16px;
  border-radius: 10px; */
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);

  background-color: white;
  /* background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px); */
  animation: ${fadeIn} 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
`;

const MenuTitle = styled.div`
  font-size: ${({ open }) => (open ? "1.2em" : "1em")};
  font-weight: 600;
  height: 40px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 6px 14px;
  margin: 0 8px;
  color: black;
  cursor: pointer; /* 클릭 가능하도록 커서 변경 */
  &:hover {
    /* background-color: rgb(10, 135, 255); */
    /* color: white; */
  }
  transition: font-size 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
`;

const MenuOuter = styled.ul`
  list-style: none;
  padding-inline-start: 0;
  display: flex;
  flex-direction: column;
  margin: 40px auto;
  width: 90%;
  align-items: center;
`;

const MenuOuterList = styled.li`
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const MenuInner = styled.ul`
  margin: 20px auto;
  list-style: none;
  padding-inline-start: 0;
  display: ${({ open }) => (open ? "flex" : "none")}; /* 하위 메뉴 열림/닫힘 */
  flex-direction: column;
  width: 100%;
  align-items: center;
  background-color: rgb(240, 240, 240);
  border-radius: 8px;
  padding: 8px 0;
  animation: ${listIn} 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
`;

const MenuInnerList = styled.li`
  list-style: none;
`;

const MenuItem = styled(NavLink)`
  display: block;
  color: black;
  padding: 6px 14px;
  text-decoration: none;
  border-radius: 8px;
  margin: 4px 16px;
  &:hover {
    background-color: rgb(10, 135, 255);
    color: white;
  }
`;

const GnbMobile = ({ color, trigger }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [openSubMenus, setOpenSubMenus] = useState({}); // 하위 메뉴 상태 관리

  console.log(openSubMenus);
  const dispatch = useDispatch();
  const scroll = useSelector((state) => state.scroll.scroll);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleSubMenu = (index) => {
    setOpenSubMenus((prevState) => {
      const newState = Object.keys(prevState).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {});

      // 클릭된 인덱스만 true로 설정
      newState[index] = !prevState[index];

      return newState;
    });
  };

  const isAnySubMenuOpen = () => {
    return Object.values(openSubMenus).some((value) => value);
  };

  useEffect(() => {
    if (isOpen) {
      dispatch(disableScroll());
      document.documentElement.style.overflow = "hidden";
    } else {
      dispatch(allowScroll());
      document.documentElement.style.overflow = "";
    }
  }, [isOpen, dispatch]);

  const loginHandler = () => {
    window.location.href = "/login";
  };

  return (
    <>
      <HamburgerIconWrap>
        <HamburgerIcon onClick={toggleMenu}>
          <HamburgerIconTop open={isOpen} color={color} />
          <HamburgerIconMid open={isOpen} color={color} />
          <HamburgerIconBottom open={isOpen} color={color} />
        </HamburgerIcon>
      </HamburgerIconWrap>
      <MobileMenuWrap open={isOpen} trigger={trigger}>
        <MobileMenu>
          <MenuOuter>
            {menuData.map((menu, index) => (
              <MenuOuterList key={index}>
                <MenuTitle
                  onClick={() => toggleSubMenu(index)}
                  open={openSubMenus[index]}
                >
                  {menu.title}
                </MenuTitle>
                <MenuInner open={openSubMenus[index]}>
                  {menu.links.map((link, linkIndex) => (
                    <MenuInnerList key={linkIndex}>
                      <MenuItem to={link.path} onClick={toggleMenu}>
                        {link.name}
                      </MenuItem>
                    </MenuInnerList>
                  ))}
                </MenuInner>
              </MenuOuterList>
            ))}
            <MenuItem onClick={handlerDonation}>Donate</MenuItem>
          </MenuOuter>
          <Button title={"Login"} link={loginHandler} />
        </MobileMenu>
      </MobileMenuWrap>
    </>
  );
};

export default GnbMobile;
