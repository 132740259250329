import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  BibleBody,
  BibleChapterVerse,
  MarginBottom,
  QtContainer,
  QtSubtitle,
  QtTitle,
  QtWrap,
  MeditBody,
  QtWrapFlex,
} from "../../styles/Qt.styled";
import Wallboard from "../../components/Wallboard";
import { getBibleRefEng, getBibleReference } from "../../components/bibleIndex";
import ErrorPage from "../ErrorPage";
import { setLanguage } from "../../actions/locale";

const OurdailyBread = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const selectedDate = useSelector((state) => state.calendar.selectedDate);
  const today = new Date();
  const initDay = moment(today).format("YYYY-MM-DD");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLanguage("ko"));
    const formattedDate = moment(selectedDate).format("YYYY-MM-DD");
    const fetchData = async () => {
      try {
        const response = await axios.get(
          // `/odb/date=${
          `https://data.swim.org/odb/date=${
            formattedDate ? formattedDate : initDay
          } 00:00:00`
        );
        setData(response.data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedDate]);

  if (loading) return <div>Loading...</div>;
  if (error) return <ErrorPage />;
  if (!data) return <ErrorPage />;

  const formatBibleText = (text) => {
    // 숫자 뒤에 콜론(:)이 있는 경우 span 태그로 감싸고 콜론을 제거
    let formattedText = text.replace(
      /(\d+):/g,
      '<span class="chapter">$1</span>'
    );

    // 숫자 앞에서 줄바꿈을 하고, 숫자를 <span> 태그로 감싸기
    // 숫자가 있는 줄을 p 태그로 감싸기
    formattedText = formattedText.replace(
      /(\d+)/g,
      '</p><p><span class="verse">$1</span>'
    );

    // 전체 텍스트를 감싸는 최상위 p 태그 추가
    formattedText = `<p>${formattedText}</p>`;

    return formattedText;
  };

  const formatLines = (text) => {
    if (!text) return "";
    return text.replace(/\r\n/g, "<br/>");
  };

  return (
    <Wallboard title={"오늘의 양식"}>
      <QtWrap>
        <QtWrapFlex>
          <QtContainer>
            {data.map((item, index) => (
              <div key={index}>
                <QtTitle
                  dangerouslySetInnerHTML={{
                    __html: formatLines(item.eng_title),
                  }}
                ></QtTitle>
                {item.eng_word ? (
                  <MeditBody>
                    <h3
                      dangerouslySetInnerHTML={{
                        __html: formatLines(item.eng_word),
                      }}
                    ></h3>
                  </MeditBody>
                ) : (
                  ""
                )}
                {item.eng_body1 ? (
                  <BibleBody>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: formatLines(item.eng_body1),
                      }}
                    ></p>
                  </BibleBody>
                ) : (
                  ""
                )}
                {item.eng_body2 ? (
                  <MeditBody>
                    <h3
                      dangerouslySetInnerHTML={{
                        __html: formatLines(item.eng_body2),
                      }}
                    ></h3>
                    {item.eng_body3 ? (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: formatLines(item.eng_body3),
                        }}
                      ></p>
                    ) : (
                      ""
                    )}
                  </MeditBody>
                ) : (
                  ""
                )}
                <BibleChapterVerse>{getBibleRefEng(item)}</BibleChapterVerse>
                <BibleBody
                  dangerouslySetInnerHTML={{
                    __html: formatBibleText(item.eng_bible_text),
                  }}
                ></BibleBody>
                <MarginBottom />
              </div>
            ))}
          </QtContainer>
          <QtContainer>
            {data.map((item, index) => (
              <div key={index}>
                <QtTitle
                  dangerouslySetInnerHTML={{
                    __html: formatLines(item.kor_title_UTF),
                  }}
                ></QtTitle>
                {item.kor_word_UTF ? (
                  <MeditBody>
                    <h3
                      dangerouslySetInnerHTML={{
                        __html: formatLines(item.kor_word_UTF),
                      }}
                    ></h3>
                  </MeditBody>
                ) : (
                  ""
                )}
                {item.kor_body1_UTF ? (
                  <BibleBody>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: formatLines(item.kor_body1_UTF),
                      }}
                    ></p>
                  </BibleBody>
                ) : (
                  ""
                )}
                {item.kor_body2_UTF ? (
                  <MeditBody>
                    <h3
                      dangerouslySetInnerHTML={{
                        __html: formatLines(item.kor_body2_UTF),
                      }}
                    ></h3>
                    {item.kor_body3_UTF ? (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: formatLines(item.kor_body3_UTF),
                        }}
                      ></p>
                    ) : (
                      ""
                    )}
                  </MeditBody>
                ) : (
                  ""
                )}
                <BibleChapterVerse>{getBibleReference(item)}</BibleChapterVerse>
                <BibleBody
                  dangerouslySetInnerHTML={{
                    __html: formatBibleText(item.kor_bible_text_UTF),
                  }}
                ></BibleBody>
                <MarginBottom />
              </div>
            ))}
          </QtContainer>
        </QtWrapFlex>
      </QtWrap>
    </Wallboard>
  );
};

export default OurdailyBread;
