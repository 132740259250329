import { SET_LANGUAGE } from "../actions/locale";

const initialState = {
  lang: "en", // 기본 언어 설정
};

export const localeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LANGUAGE:
      return { ...state, lang: action.payload };
    default:
      return state;
  }
};
