export const menuData = [
  {
    title: "SWIM",
    links: [
      { name: "소개", path: "/2" },
      { name: "연혁", path: "/3" },
    ],
  },
  {
    title: "Mission",
    links: [
      { name: "SWIM 선교모델", path: "/1" },
      { name: "성도,하나님의 사람", path: "/4" },
      { name: "선교사, 후방성도 협력", path: "/6" },
      { name: "IT융합 사역", path: "/5" },
      { name: "SWIM Media", path: "/7" },
    ],
  },
  {
    title: "Article",
    links: [{ name: "News", path: "/news" }],
  },
  {
    title: "QT",
    links: [
      { name: "QT", path: "/qt" },
      { name: "QT신청하기", path: "/subscribe" },
    ],
  },
];

export const menuDataFooter = [
  {
    title: "SWIM",
    links: [
      { name: "소개", path: "/2" },
      { name: "연혁", path: "/3" },
    ],
  },
  {
    title: "Mission",
    links: [
      { name: "SWIM 선교모델", path: "/1" },
      { name: "성도,하나님의 사람", path: "/4" },
      { name: "선교사, 후방성도 협력", path: "/5" },
      { name: "IT융합 사역", path: "/6" },
      { name: "SWIM Media", path: "/7" },
    ],
  },
  {
    title: "Article",
    links: [{ name: "News", path: "/news" }],
  },
  {
    title: "QT",
    links: [
      { name: "QT", path: "/qt" },
      { name: "QT신청하기", path: "/subscribe" },
    ],
  },
  {
    links: [{ name: "structure", path: "/structure" }],
  },
];
