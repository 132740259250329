import { combineReducers } from "redux";
import dataReducer from "./dataReducer";
import bibleReducer from "./bibleReducer";
import scrollReducer from "./scrollReducer";
import emailReducer from "./emailReducer";
import authReducer from "./authReducer";
import dateReducer from "./dateReducer";
import modalReducer from "./modalReducer";
import { localeReducer } from "./localeReducer";

const rootReducer = combineReducers({
  data: dataReducer,
  bibleData: bibleReducer,
  scroll: scrollReducer,
  email: emailReducer,
  auth: authReducer,
  calendar: dateReducer,
  modal: modalReducer,
  locale: localeReducer,
});

export default rootReducer;
