import React, { useState, useEffect, useCallback } from "react";
import Calendar from "react-calendar";
import "./cal.css";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { selectDate } from "../actions/calendar";
import { modalClose } from "../actions/modal";

const CalendarBtn = styled.button`
  border-radius: 16px;
  border: none;
  height: 45px;
  width: 100%;
  max-width: 400px;
  text-align: center;
  font-weight: bolder;
  font-size: 1.5em;
  color: white;
  background-color: rgb(29 114 235);
  cursor: pointer;
  &:hover {
    background-color: #81adeb;
  }
`;

const CalendarWrap = styled.div`
  position: absolute;
  top: 74px;
  left: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  @media screen and (max-width: 767px) {
    position: relative;
    width: 100%;
    top: -40px;
    left: 0;
    margin: 12px;
  }

  animation: ${({ close }) =>
    close
      ? "hide 0.3s cubic-bezier(0.56, 0, 0.835, 0.16);"
      : "show 0.5s cubic-bezier(0.165, 0.84, 0.44, 1)"};
  @keyframes show {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }

  @keyframes hide {
    from {
      transform: translateX(0);
      opacity: 1;
    }
    to {
      transform: translateX(-100%);
      opacity: 0;
    }
  }
`;

const CalUiDescFlex = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 4px;
  width: 100%;
  max-width: 390px;
  background: white;
  border-radius: 16px;
  box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.05);
  border: 1px solid #ddd;
`;

const Desc = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({ color }) => color};
  gap: 8px;
  margin: 0 10px;
`;

const CalBox = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50px;
  background-color: ${({ color }) => color};
`;

const CalendarPicker = () => {
  const dispatch = useDispatch();
  const selectedDate = useSelector((state) => state.calendar.selectedDate);
  const [closing, setClosing] = useState(false);
  const today = new Date();
  const yearArw = null;
  const locale = useSelector((state) => state.locale.lang);

  console.log(locale);

  const handleDateChange = useCallback(
    (date, event) => {
      event.stopPropagation();
      dispatch(selectDate({ date }));
    },
    [dispatch]
  );

  const isDateClickable = useCallback(
    (date) => {
      return date >= today;
    },
    [today]
  );

  const closeHandler = () => {
    setClosing(true);
  };

  useEffect(() => {
    if (closing) {
      const timer = setTimeout(() => {
        setClosing(false);
        dispatch(modalClose());
      }, 250);
      return () => clearTimeout(timer);
    }
  }, [closing]);

  return (
    <CalendarWrap close={closing}>
      <CalUiDescFlex>
        <Desc color={"#006edc"}>
          <CalBox color={"#006edc"} />
          current
        </Desc>
        <Desc color={"#0d0d86"}>
          <CalBox color={"#0d0d86"} />
          today
        </Desc>
      </CalUiDescFlex>
      <Calendar
        tileDisabled={({ date }) => isDateClickable(date)}
        value={selectedDate}
        onChange={handleDateChange}
        locale={locale}
        next2Label={yearArw}
        prev2Label={yearArw}
      />
      <CalendarBtn onClick={closeHandler}>✓</CalendarBtn>
    </CalendarWrap>
  );
};

export default CalendarPicker;
