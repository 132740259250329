import React from "react";
import styled from "styled-components";

const MainBannerContainer = styled.div`
  max-width: 1200px;
  width: 100%;
  height: auto;
  margin: 0;
  position: relative;
`;

const MainBannerImg = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: right;
  @media screen and (max-width: 767px) {
    height: 75vw;
    width: 100%;
    object-position: ${({ layout }) => (layout == "right" ? "right" : "left")};
  }
`;

const ContentsWrap = styled.div`
  position: absolute;
  ${({ layout }) =>
    layout == "right" ? "right: 0; align-items: flex-end;" : "left: 0;"};
  margin: 10%;
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media screen and (max-width: 767px) {
    margin: 3%;
    width: 80%;
    height: fit-content;
  }
`;

const Title = styled.h1`
  margin-block-end: 0;
  margin-block-start: 0;
  color: ${({ color }) => color};
  font-size: 60px;
  @media screen and (max-width: 767px) {
    font-size: 6vw;
  }
`;

const Description = styled.p`
  font-family: "Pretendard-Regular";
  font-size: 1.2em;
  color: ${({ color }) => color};
  text-align: ${({ layout }) => layout};
  @media screen and (max-width: 767px) {
    font-size: 1em;
  }
`;

const CallToAction = styled.button`
  border: none;
  border-radius: 100px;
  height: 60px;
  max-width: 240px;
  padding: 12px 24px;
  background-color: #ffe800;
  font-size: 1.3em;
  font-weight: 600;
  color: #0e3975;
  cursor: pointer;
  &:hover {
    background-color: #fff270;
  }
  @media screen and (max-width: 767px) {
    font-size: 1em;
    height: 40px;
    max-width: 140px;
  }
`;

const MainBanner = ({
  imgUrl,
  layout,
  title,
  description,
  color,
  ctaText,
  ctaHandle,
}) => {
  return (
    <MainBannerContainer>
      <ContentsWrap layout={layout}>
        <Title color={color}>{title}</Title>
        <Description color={color} layout={layout}>
          {description
            ? description.split("\n").map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))
            : ""}
        </Description>
        {ctaText ? (
          <CallToAction onClick={ctaHandle}>{ctaText}</CallToAction>
        ) : (
          ""
        )}
      </ContentsWrap>
      <MainBannerImg src={imgUrl} layout={layout} />
    </MainBannerContainer>
  );
};

export default MainBanner;
