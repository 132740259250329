import React from "react";
import Posts from "./Posts";

const Article = () => {
  return (
    <div>
      <Posts />
    </div>
  );
};

export default Article;
