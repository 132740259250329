import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Wallboard from "../../components/Wallboard";
import { getBibleReference } from "../../components/bibleIndex";
import ErrorPage from "../ErrorPage";
import styled from "styled-components";
import { Helmet } from "react-helmet-async";
import { setLanguage } from "../../actions/locale";

const QtWrap = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fffefc;
`;

const QtWrapFlex = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  width: fit-content;
  margin: auto;
`;

const QtContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 0 auto;
`;

const BibleBody = styled.p`
  font-family: "Pretendard-Regular";
  line-height: 1.8em;
  color: #353231;
  margin: 0 16px 2em 16px;
  .chapter {
    font-size: 1.5em;
    font-weight: 600;
    color: #0e3975;
    line-height: 2em;
  }
  .verse {
    font-size: 0.8em;
    font-weight: 600;
    color: rgb(29 114 235);
    margin: 0 4px;
  }
`;

const MeditBody = styled.p`
  font-family: "Pretendard-Regular";
  line-height: 2em;
  color: #333;
  padding: 0.7em 20px;

  h3 {
    font-family: "YoonChildfundkoreaDaeHan";
    color: #0e3975;
    font-size: 1.5em;
  }

  h4 {
    font-family: "YoonChildfundkoreaDaeHan";
    color: #0e3975;
    border-radius: 16px;
    background-color: #f5f5fb;
    padding: 12px 16px;
  }
`;

const BibleChapterVerse = styled.h3`
  font-family: "YoonChildfundkoreaDaeHan";
  font-weight: 600;
  color: #333;
  margin: 0 16px 1em 16px;
`;

const QtTitle = styled.h1`
  font-family: "YoonChildfundkoreaDaeHan";
  color: #444;
  margin: 2em 16px 1em;
`;

const MarginBottom = styled.div`
  height: 80px;
  width: 100%;
`;

const LikeJesus = () => {
  const [data, setData] = useState(null); // 데이터를 객체로 받음
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const selectedDate = useSelector((state) => state.calendar.selectedDate);
  const today = new Date();
  const initDay = moment(today).format("YYYY-MM-DD");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLanguage("ko"));
    const formattedDate = moment(selectedDate).format("YYYY-MM-DD");
    const fetchData = async () => {
      try {
        const response = await axios.get(
          // `/likejesus/date=${
          `https://data.swim.org/likejesus/date=${
            formattedDate ? formattedDate : initDay
          } 00:00:00`
        );
        setData(response.data[0]);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedDate]);

  if (loading) return <div>Loading...</div>;
  if (error) return <ErrorPage />;
  if (!data) return <ErrorPage />;

  const formatBibleText = (text) => {
    let formattedText = text.replace(
      /(\d+):/g,
      '<span class="chapter">$1</span>'
    );
    formattedText = formattedText.replace(
      /(\d+)/g,
      '</p><p><span class="verse">$1</span>'
    );
    formattedText = `<p>${formattedText}</p>`;
    return formattedText;
  };

  const formatLines = (text) => {
    if (!text) return "";
    return text.replace(/\r\n/g, "<br/>");
  };

  return (
    <>
      <Helmet>
        <title>SWIM QT 예조</title>
        <meta name="description" content="어린이 큐티" />
        <meta
          name="keywords"
          content="예수님이 좋아요, 어린이큐티, 어린이 QT, 어린이 큐티"
        />
      </Helmet>
      <Wallboard title={"예수님이 좋아요"}>
        <QtWrap>
          <QtContainer>
            <QtTitle
              dangerouslySetInnerHTML={{
                __html: formatLines(data.title_UTF),
              }}
            ></QtTitle>
            <MeditBody>
              <h3>성경 속으로</h3>
              <BibleChapterVerse>{getBibleReference(data)}</BibleChapterVerse>
              <BibleBody
                dangerouslySetInnerHTML={{
                  __html: formatBibleText(data.bible_text_UTF),
                }}
              ></BibleBody>
              <hr />
              <h5
                dangerouslySetInnerHTML={{
                  __html: formatLines(data.word_comment_UTF),
                }}
              ></h5>
            </MeditBody>
            <MeditBody>
              <h3>말씀 콩닥</h3>
              <p
                dangerouslySetInnerHTML={{
                  __html: formatLines(data.detail_text_UTF),
                }}
              ></p>
              <h4
                dangerouslySetInnerHTML={{
                  __html: formatLines(data.word_life_UTF),
                }}
              ></h4>
            </MeditBody>
            <MeditBody>
              <h3>기도하기</h3>
              <p
                dangerouslySetInnerHTML={{
                  __html: formatLines(data.knee_UTF),
                }}
              ></p>
            </MeditBody>
            <MarginBottom />
          </QtContainer>
        </QtWrap>
      </Wallboard>
    </>
  );
};

export default LikeJesus;
