import styled from "styled-components";

export const QtWrap = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fffefc;
`;

export const QtWrapFlex = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  width: fit-content;
  margin: auto;
`;

export const QtContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 0 auto;
`;

export const BibleBody = styled.p`
  font-family: "Pretendard-Regular";
  line-height: 1.7em;
  color: #353231;
  margin: 0 16px 2em 16px;
  .chapter {
    font-size: 1.4em;
    font-weight: 600;
    color: #0e3975;
    line-height: 2em;
  }
  .verse {
    font-size: 0.8em;
    font-weight: 600;
    color: rgb(29 114 235);
    margin: 0 4px;
  }
`;

export const MeditBody = styled.p`
  font-family: "Pretendard-Regular";
  line-height: 1.7em;
  color: #333;
  padding: 0.7em 20px;
  border-radius: 16px;
  background-color: #f5f5fb;

  h3 {
    color: #0e3975;
  }
`;

export const BibleChapterVerse = styled.h3`
  font-family: "Pretendard-Regular";
  font-weight: 600;
  color: #333;
  margin: 0 16px 1em 16px;
`;

export const QtTitle = styled.h1`
  font-family: "Pretendard-Regular";
  color: #444;
  margin: 2em 16px 1em;
`;

export const QtSubtitle = styled.div`
  font-family: "Pretendard-Regular";
  line-height: 1.7em;
  color: #333;
  margin-bottom: 2em;
  padding: 0.7em 20px;
  border-radius: 16px;
  background-color: #f5f5fb;

  h3 {
    color: #0e3975;
  }
  p {
    font-weight: 600;
  }
`;

export const MarginBottom = styled.div`
  height: 80px;
  width: 100%;
`;

export const VideoPlayer = styled.video`
  margin-bottom: 2em;
  width: 100%;
  height: auto;
  border-radius: 16px;
`;

export const VideoContainer = styled.div`
  position: ${({ display }) => (display ? "sticky" : "relative")};
  top: ${({ display }) => (display ? "-45px" : "0")};
`;
export const VideoWrap = styled.div`
  max-height: ${({ display }) => (display ? "375px" : "0")};
  overflow: hidden;
  transition: max-height 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
`;

export const VideoTitleWrap = styled.div`
  font-family: "Pretendard-Regular";
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 45px;
  align-items: center;
  color: #ff6b00;
  :nth-child(1) {
    margin: 0 16px;
  }
`;

export const VideoButton = styled.button`
  width: 34px;
  height: 34px;
  transform: ${({ rotate }) => (rotate ? "rotate(180deg)" : "rotate(90deg)")};
  /* border: 2px solid #ff6b00; */
  border-radius: 100px;
  border: none;
  background-color: #ff6b00;
  /* margin-block-end: 0;
  margin-block-start: 0; */
  color: white;
  font-size: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
`;
