import { EMAIL_SUBMIT } from "../actions/subscribe";

const initialState = {
  subscribe: false,
};

const emailReducer = (state = initialState, action) => {
  switch (action.type) {
    case EMAIL_SUBMIT:
      return {
        ...state,
        subscribe: action.payload,
      };
    default:
      return state;
  }
};

export default emailReducer;
