import React from "react";
import styled from "styled-components";
import { ReactComponent as Blob } from "../img/blob.svg";

const BallWrap = styled.div`
  width: 100%;
  height: 360px;
  overflow: hidden;
`;

const Backdrop = styled.div`
  position: inherit;
  top: 0;
  left: 0;
  fill: #023f92;
  animation: move 70s ease-in-out infinite;
  transform-origin: 20% 20%;
  filter: blur(15px);

  @keyframes move {
    0% {
      transform: scale(1.3) translate(0vw, 20vh) rotate(-20deg);
    }
    38% {
      transform: scale(2, 1) translate(50vw, 25vh) rotate(160deg);
    }
    40% {
      transform: scale(2, 1) translate(50vw, 25vh) rotate(160deg);
    }
    78% {
      transform: scale(1) translate(-50vw, 15vh);
    }
    80% {
      transform: scale(1) translate(-50vw, 15vh);
    }
    100% {
      transform: scale(1.3) translate(0vw, 20vh) rotate(-20deg);
    }
  }
`;

const Ball = () => {
  return (
    <BallWrap>
      <Backdrop>
        <Blob />
      </Backdrop>
    </BallWrap>
  );
};

export default Ball;
