// 성경인덱스
const bibleBooks = [
  "",
  "창세기",
  "출애굽기",
  "레위기",
  "민수기",
  "신명기",
  "여호수아",
  "사사기",
  "룻기",
  "사무엘상",
  "사무엘하",
  "열왕기상",
  "열왕기하",
  "역대상",
  "역대하",
  "에스라",
  "느헤미야",
  "에스더",
  "욥기",
  "시편",
  "잠언",
  "전도서",
  "아가",
  "이사야",
  "예레미야",
  "예레미야 애가",
  "에스겔",
  "다니엘",
  "호세아",
  "요엘",
  "아모스",
  "오바댜",
  "요나",
  "미가",
  "나훔",
  "하박국",
  "스바냐",
  "학개",
  "스가랴",
  "말라기",
  "마태복음",
  "마가복음",
  "누가복음",
  "요한복음",
  "사도행전",
  "로마서",
  "고린도전서",
  "고린도후서",
  "갈라디아서",
  "에베소서",
  "빌립보서",
  "골로새서",
  "데살로니가전서",
  "데살로니가후서",
  "디모데전서",
  "디모데후서",
  "디도서",
  "빌레몬서",
  "히브리서",
  "야고보서",
  "베드로전서",
  "베드로후서",
  "요한일서",
  "요한이서",
  "요한삼서",
  "유다서",
  "요한계시록",
];
const bibleBooksEng = [
  "",
  "Genesis",
  "Exodus",
  "Leviticus",
  "Numbers",
  "Deuteronomy",
  "Joshua",
  "Judges",
  "Ruth",
  "1 Samuel",
  "2 Samuel",
  "1 Kings",
  "2 Kings",
  "1 Chronicles",
  "2 Chronicles",
  "Ezra",
  "Nehemiah",
  "Esther",
  "Job",
  "Psalms",
  "Proverbs",
  "Ecclesiastes",
  "Song of Solomon",
  "Isaiah",
  "Jeremiah",
  "Lamentations",
  "Ezekiel",
  "Daniel",
  "Hosea",
  "Joel",
  "Amos",
  "Obadiah",
  "Jonah",
  "Micah",
  "Nahum",
  "Habakkuk",
  "Zephaniah",
  "Haggai",
  "Zechariah",
  "Malachi",
  "Matthew",
  "Mark",
  "Luke",
  "John",
  "Acts",
  "Romans",
  "1 Corinthians",
  "2 Corinthians",
  "Galatians",
  "Ephesians",
  "Philippians",
  "Colossians",
  "1 Thessalonians",
  "2 Thessalonians",
  "1 Timothy",
  "2 Timothy",
  "Titus",
  "Philemon",
  "Hebrews",
  "James",
  "1 Peter",
  "2 Peter",
  "1 John",
  "2 John",
  "3 John",
  "Jude",
  "Revelation",
];
const bibleBooksJp = [
  "",
  "創世記",
  "出エジプト記",
  "レビ記",
  "民数記",
  "申命記",
  "ヨシュア記",
  "士師記",
  "ルツ記",
  "サムエル記上",
  "サムエル記下",
  "列王記上",
  "列王記下",
  "歴代誌上",
  "歴代誌下",
  "エズラ記",
  "ネヘミヤ記",
  "エステル記",
  "ヨブ記",
  "詩篇",
  "箴言",
  "伝道者の書",
  "雅歌",
  "イザヤ書",
  "エレミヤ書",
  "哀歌",
  "エゼキエル書",
  "ダニエル書",
  "ホセア書",
  "ヨエル書",
  "アモス書",
  "オバデヤ書",
  "ヨナ書",
  "ミカ書",
  "ナホム書",
  "ハバクク書",
  "ゼパニヤ書",
  "ハガイ書",
  "ゼカリヤ書",
  "マラキ書",
  "マタイによる福音書",
  "マルコによる福音書",
  "ルカによる福音書",
  "ヨハネによる福音書",
  "使徒行伝",
  "ローマ人への手紙",
  "コリント人への第一の手紙",
  "コリント人への第二の手紙",
  "ガラテヤ人への手紙",
  "エペソ人への手紙",
  "ピリピ人への手紙",
  "コロサイ人への手紙",
  "テサロニケ人への第一の手紙",
  "テサロニケ人への第二の手紙",
  "テモテへの第一の手紙",
  "テモテへの第二の手紙",
  "テトスへの手紙",
  "ピレモンへの手紙",
  "ヘブル人への手紙",
  "ヤコブの手紙",
  "ペテロの第一の手紙",
  "ペテロの第二の手紙",
  "ヨハネの第一の手紙",
  "ヨハネの第二の手紙",
  "ヨハネの第三の手紙",
  "ユダの手紙",
  "ヨハネの黙示録",
];
const bibleBooksAr = [
  "",
  "التكوين",
  "الخروج",
  "اللاويين",
  "العدد",
  "التثنية",
  "يشوع",
  "القضاة",
  "راعوث",
  "صموئيل الأول",
  "صموئيل الثاني",
  "الملوك الأول",
  "الملوك الثاني",
  "أخبار الأيام الأول",
  "أخبار الأيام الثاني",
  "عزرا",
  "نحميا",
  "أستير",
  "أيوب",
  "المزامير",
  "الأمثال",
  "الجامعة",
  "نشيد الأنشاد",
  "إشعياء",
  "إرميا",
  "مراثي إرميا",
  "حزقيال",
  "دانيال",
  "هوشع",
  "يوئيل",
  "عاموس",
  "عوبديا",
  "يونان",
  "ميخا",
  "ناحوم",
  "حبقوق",
  "صفنيا",
  "حجي",
  "زكريا",
  "ملاخي",
  "متى",
  "مرقس",
  "لوقا",
  "يوحنا",
  "أعمال الرسل",
  "رومية",
  "كورنثوس الأولى",
  "كورنثوس الثانية",
  "غلاطية",
  "أفسس",
  "فيلبي",
  "كولوسي",
  "تسالونيكي الأولى",
  "تسالونيكي الثانية",
  "تيموثاوس الأولى",
  "تيموثاوس الثانية",
  "تيطس",
  "فليمون",
  "العبرانيين",
  "يعقوب",
  "بطرس الأولى",
  "بطرس الثانية",
  "يوحنا الأولى",
  "يوحنا الثانية",
  "يوحنا الثالثة",
  "يهوذا",
  "رؤيا يوحنا",
];

/**
 * 성경코드를 인덱싱된 이름과 매칭하고,
 * 이름 장 절 데이터를 축약해서 리턴함
 * @param {"object"} data
 * @returns {string} "name chapter : verse ~ verse"
 */
export const getBibleReference = (data) => {
  const {
    bible_code,
    bible_start_jang,
    bible_start_jul,
    bible_end_jang,
    bible_end_jul,
  } = data;

  const getBibleBookName = (code) => {
    const index = parseInt(code, 10); // 코드가 문자열일 경우 정수로 변환
    return bibleBooks[index] || "알 수 없음"; // 코드가 유효하지 않은 경우 기본값 반환
  };

  const bookName = getBibleBookName(bible_code);

  if (bible_start_jang === bible_end_jang) {
    return `${bookName} ${bible_start_jang} : ${bible_start_jul} ~ ${bible_end_jul}`;
  } else if (bible_end_jang != null) {
    return `${bookName} ${bible_start_jang} : ${bible_start_jul} ~ ${bible_end_jang} : ${bible_end_jul}`;
  } else {
    return `${bookName} ${bible_start_jang} : ${bible_start_jul}`;
  }
};

//영어버전
export const getBibleRefEng = (data) => {
  const {
    bible_code,
    bible_start_jang,
    bible_start_jul,
    bible_end_jang,
    bible_end_jul,
  } = data;

  const getBibleBookName = (code) => {
    const index = parseInt(code, 10); // 코드가 문자열일 경우 정수로 변환
    return bibleBooksEng[index] || "알 수 없음"; // 코드가 유효하지 않은 경우 기본값 반환
  };

  const bookName = getBibleBookName(bible_code);

  if (bible_start_jang === bible_end_jang) {
    return `${bookName} ${bible_start_jang} : ${bible_start_jul} ~ ${bible_end_jul}`;
  } else {
    return `${bookName} ${bible_start_jang} : ${bible_start_jul} ~ ${bible_end_jang} : ${bible_end_jul}`;
  }
};

//일어버전
export const getBibleRefJp = (data) => {
  const {
    bible_code,
    bible_start_jang,
    bible_start_jul,
    bible_end_jang,
    bible_end_jul,
  } = data;

  const getBibleBookName = (code) => {
    const index = parseInt(code, 10); // 코드가 문자열일 경우 정수로 변환
    return bibleBooksJp[index] || "알 수 없음"; // 코드가 유효하지 않은 경우 기본값 반환
  };

  const bookName = getBibleBookName(bible_code);

  if (bible_start_jang === bible_end_jang) {
    return `${bookName} ${bible_start_jang} : ${bible_start_jul} ~ ${bible_end_jul}`;
  } else {
    return `${bookName} ${bible_start_jang} : ${bible_start_jul} ~ ${bible_end_jang} : ${bible_end_jul}`;
  }
};

//아랍어 버전
export const getBibleRefAr = (data) => {
  const {
    bible_code,
    bible_start_jang,
    bible_start_jul,
    bible_end_jang,
    bible_end_jul,
  } = data;

  const getBibleBookName = (code) => {
    const index = parseInt(code, 10); // 코드가 문자열일 경우 정수로 변환
    return bibleBooksAr[index] || "알 수 없음"; // 코드가 유효하지 않은 경우 기본값 반환
  };

  const bookName = getBibleBookName(bible_code);

  if (bible_start_jang === bible_end_jang) {
    return `${bookName} ${bible_start_jang} : ${bible_start_jul} ~ ${bible_end_jul}`;
  } else {
    return `${bookName} ${bible_start_jang} : ${bible_start_jul} ~ ${bible_end_jang} : ${bible_end_jul}`;
  }
};

//스펄전은 다른 방식을 쓰고 있네? 하 참ㅋㅋ
export const getBibleRefSpur = (data) => {
  const { bible_code, bible_start_jang, bible_start_jul } = data;

  const getBibleBookName = (code) => {
    const index = parseInt(code, 10); // 코드가 문자열일 경우 정수로 변환
    return bibleBooks[index] || "알 수 없음"; // 코드가 유효하지 않은 경우 기본값 반환
  };
  const bookName = getBibleBookName(bible_code);

  return `${bookName} ${bible_start_jang} : ${bible_start_jul}`;
};

export const formatLines = (text) => {
  if (!text) return "";
  return text.replace(/\r\n/g, "<br/>");
};
