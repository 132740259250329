// actions.js
import axios from "axios";

export const FETCH_BIBLE_REQUEST = "FETCH_BIBLE_REQUEST";
export const FETCH_BIBLE_SUCCESS = "FETCH_BIBLE_SUCCESS";
export const FETCH_BIBLE_FAILURE = "FETCH_BIBLE_FAILURE";

export const fetchBibleRequest = () => ({
  type: FETCH_BIBLE_REQUEST,
});

export const fetchBibleSuccess = (data) => ({
  type: FETCH_BIBLE_SUCCESS,
  payload: data,
});

export const fetchBibleFailure = (error) => ({
  type: FETCH_BIBLE_FAILURE,
  payload: error,
});

export const fetchBible = (url) => {
  const dataUrl = url;
  return async (dispatch) => {
    dispatch(fetchBibleRequest());
    try {
      const response = await axios.get(dataUrl);
      dispatch(fetchBibleSuccess(response.data));
    } catch (error) {
      dispatch(fetchBibleFailure(error.message));
    }
  };
};
