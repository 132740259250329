import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { menuData } from "../menudata";
import Button from "./button";
import { ReactComponent as LogoImg } from "../img/logo_swim.svg";

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: center;
`;

const GnbContentWrap = styled.div`
  height: inherit;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  margin: auto;
`;

const MenuContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover > div > div,
  > div {
    display: block;
  }
`;

const MenuTitle = styled.div`
  font-weight: 600;
  font-size: 0.9em;
  letter-spacing: 1px;
  color: ${({ color }) => color};
  transition: color 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  cursor: pointer;
  padding: 8px;
`;

const MenuTitleLink = styled(NavLink)`
  text-decoration: none;
  font-weight: 600;
  font-size: 0.9em;
  color: ${({ color }) => color};
  transition: color 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  cursor: pointer;
  padding: 8px;
`;

const DropdownContainer = styled.div`
  display: none;
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  z-index: 1;
  border-radius: 10px;
  min-width: 200px;
  overflow: hidden;
  border: 1px solid #ddd;
  box-shadow: inset 0px 0px 0.5px 0.5px #fff;
  transform-origin: top;
  transform: perspective(600px) rotateX(-30deg) translateY(-5px);
  transition: transform 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);

  ${MenuContainer}:hover & {
    display: block;
    transform: perspective(600px) rotateX(0) translateY(0);
  }
`;

const DropdownContainerShadow = styled.div`
  display: none;
  position: absolute;
  width: fit-content;
  top: 100%;
  left: 0;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 10px;

  transform-origin: top;
  transform: perspective(600px) rotateX(-30deg) translateY(-5px);
  transition: transform 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);

  ${MenuContainer}:hover & {
    display: block;
    transform: perspective(600px) rotateX(0) translateY(0);
  }
`;

const DropdownLink = styled(NavLink)`
  display: block;
  color: black;
  padding: 6px 14px;
  text-decoration: none;
  border-radius: 8px;
  margin: 4px;
  &:hover {
    background-color: rgb(10, 135, 255);
    color: white;
  }
`;

const Logo = styled(LogoImg)`
  cursor: pointer;
  height: auto;
  max-width: 75px;
  margin: 4px;
  margin-left: 16px;
  .d {
    fill: ${({ trigger }) =>
      trigger ? "rgb(255 255 255)" : " rgb(29 114 235)"};
  }
  transition: fill 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
`;

const Menu = ({ title, links, color }) => (
  <>
    {title ? (
      <MenuContainer>
        <MenuTitle color={color}>{title}</MenuTitle>
        <DropdownContainerShadow>
          <DropdownContainer>
            {links.map((link, index) => (
              <DropdownLink key={index} to={link.path}>
                {link.name}
              </DropdownLink>
            ))}
          </DropdownContainer>
        </DropdownContainerShadow>
      </MenuContainer>
    ) : (
      ""
    )}
  </>
);

export const handlerDonation = () => {
  window.open(
    "https://www.ihappynanum.com/Nanum/nanum/banner/bridge/93XSS2KFDL"
  );
};

const GnbPc = ({ trigger }) => {
  const gColor = () => {
    if (trigger) return "rgb(255 255 255)";
    else return "rgb(0 0 0)";
  };

  return (
    <Flex>
      {menuData.map((menu, index) => (
        <Menu
          key={index}
          title={menu.title}
          links={menu.links}
          color={gColor}
        />
      ))}
      <MenuContainer>
        <MenuTitleLink onClick={handlerDonation} color={gColor}>
          ♥︎ Donate
        </MenuTitleLink>
      </MenuContainer>
    </Flex>
  );
};

export default GnbPc;
