import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { ItemContainer, ItemName } from "../pages/QtList";
import moment from "moment";

export const ItemFlex = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;

export const ItemGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 8px;
  margin-top: 24px;
  width: 100%;
`;

export const ItemBtnLink = styled.button`
  border: none;
  border-radius: 12px;
  height: 40px;
  font-size: 0.9em;
  color: #333;
  cursor: pointer;
  &:hover {
    background-color: rgb(54, 175, 255);
    color: white;
  }
`;

const today = moment(new Date()).format("YYMMDD");
console.log(today);
const lifeUrl = `http://cloudvod1.cgntv.net/_NewMP4/1/MAQT${today}.h854x480.mp4`;
const livingLifeUrl = `http://cloudvod1.cgntv.net/_NewMP4/2/MAQL${today}.h854x480.mp4`;
const japaneseQt = `http://cloudvod1.cgntv.net/_NewMP4/3/JMAQ${today}.h854x480.mp4`;
const chineseQt = `http://cloudvod1.cgntv.net/_NewMP4/4/TMAQ${today}.h854x480.mp4`;

const handleOpenVideoLife = () => {
  window.open(lifeUrl);
};
const handleOpenVideoLiving = () => {
  window.open(livingLifeUrl);
};
const handleOpenVideoJap = () => {
  window.open(japaneseQt);
};
const handleOpenVideoChi = () => {
  window.open(chineseQt);
};

const QtLinkCard = () => {
  return (
    <ItemContainer>
      <ItemFlex>
        <ItemName color="#ec1c24">영상 큐티</ItemName>
        <ItemGrid>
          <ItemBtnLink onClick={handleOpenVideoLife} url={lifeUrl}>
            생명의 삶
          </ItemBtnLink>
          <ItemBtnLink onClick={handleOpenVideoLiving}>living life</ItemBtnLink>
          <ItemBtnLink onClick={handleOpenVideoJap}>日本語</ItemBtnLink>
          <ItemBtnLink onClick={handleOpenVideoChi}>中國語</ItemBtnLink>
        </ItemGrid>
      </ItemFlex>
    </ItemContainer>
  );
};

export default QtLinkCard;
