import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { getBibleReference } from "../components/bibleIndex";
import ImgSlider from "../components/ImgSlider";
import TagNav from "../components/tagNav";
import QtLinkCard from "../components/qtLinkCard";
import QtLanguageCard from "../components/qtLanguageCard";
import Dailyword from "../components/dailyword";
import { Helmet } from "react-helmet-async";
import QtChildrenCard from "../components/qtChidrenCard";

const QtListBg = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  /* background-image: url("https://data.swim.org/img/wallpaper_2.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; */
  background: linear-gradient(
    349deg,
    rgba(94, 9, 121, 1) 0%,
    rgba(54, 175, 255, 1) 100%
  );
  padding: 20px 0;
`;

const QtTopMargin = styled.div`
  margin-top: 60px;
`;

const QtListWrap = styled.div`
  margin: auto;
  max-width: 1200px;
`;
const QtListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  @media screen and (max-width: 767px) {
    margin: 0;
  }
`;

const QtListTitle = styled.h1`
  color: white;
  font-family: "NanumSquareNeoBold";
  margin: 50px 0;
  font-size: 2em;
  @media screen and (max-width: 800px) {
    font-size: 1.2em;
    margin: 50px 16px;
  }
`;

const QtListTitleSub = styled.h2`
  color: white;
  font-family: "NanumSquareNeoBold";
  font-size: 2em;

  margin-block-start: 0;
  margin-block-end: 0;
  margin: 0 4px;
  @media screen and (max-width: 800px) {
    font-size: 1.2em;
    margin: 20px 4px 12px;
  }
`;

const QtListTitleFlex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 780px;
  @media screen and (max-width: 800px) {
    width: 390px;
    flex-direction: column;
    align-items: flex-start;
  }
  @media screen and (max-width: 420px) {
    width: 350px;
  }
  @media screen and (max-width: 321px) {
    width: 270px;
  }
`;

const QtProviderTitle = styled.h1`
  color: white;
  font-family: "NanumSquareNeoBold";
  font-size: 2em;
  margin: 40px 0;
  @media screen and (max-width: 800px) {
    font-size: 1.2em;
    margin: 20px 4px 12px;
  }
`;

const QtListGrid = styled.div`
  max-width: fit-content;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`;

export const ItemContainer = styled.div`
  cursor: pointer;
  margin: 4pt;
  padding: 24px;
  /* max-width: 367px;
  min-width: 340px; */
  width: 367px;
  border: 1px solid #ddd;
  border-radius: 16px;
  box-shadow: 1pt 2pt 5px 3px #a3a3a320;
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);

  &:hover {
    background-color: rgb(255, 255, 255);
  }
  @media screen and (max-width: 767px) {
    max-width: 367px;
  }
  @media screen and (max-width: 420px) {
    max-width: 300px;
  }
  @media screen and (max-width: 321px) {
    width: 250px;
  }
`;

const ItemFlex = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 24px;
  justify-content: space-between;
`;

const ItemSubFlex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ItemRight = styled.p`
  /* font-family: "Iropke Batang"; */
  margin-block-end: 0;
  margin-block-start: 0;
  color: #777;
  font-size: 0.8em;
`;

const ItemTitle = styled.p`
  /* font-family: "Iropke Batang"; */
  margin-block-end: 0;
  margin-block-start: 0;
  color: #555;
`;

export const ItemName = styled.h3`
  font-family: "NanumSquareNeoExtraBold";
  margin-block-end: 0;
  margin-block-start: 0;
  color: ${({ color }) => color};
  font-size: x-large;
`;

const ItemSubtitle = styled.p`
  /* font-family: "Iropke Batang"; */
  margin-block-end: 0;
  margin-block-start: 0;
  font-size: 0.8em;
`;

// 모달을 위한 스타일 추가
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
  width: 100%;
  z-index: 1001;
`;

const bounceAnimation = keyframes`
  0% { transform: translateY(-100%); opacity: 0; }
  10% { transform: translateY(10%) scaleX(1.1) scaleY(0.9); opacity: 1; }
  25% { transform: translateY(0); opacity: 1; }
  100% { transform: translateY(0); opacity: 1; }
`;

const AnimatedText = styled.span`
  display: inline-block;
  font-weight: 400;
  animation: ${bounceAnimation} 1s ease-in-out;
`;

const color = ["#1976D2", "#E64A19", "#4CAF50", "#673AB7"];

const Item = ({ url, item, color }) => {
  const navigate = useNavigate();
  const linkHandler = () => {
    const link = `/qt/${url}`;
    navigate(link);
  };

  return (
    <ItemContainer onClick={linkHandler}>
      <ItemFlex>
        <ItemName color={color}>{item.source}</ItemName>
        <ItemTitle>{item.title}</ItemTitle>
        <ItemSubFlex>
          <ItemSubtitle>{getBibleReference(item)}</ItemSubtitle>
          <ItemRight>{"(" + item.copy_right + " 제공)"}</ItemRight>
        </ItemSubFlex>
      </ItemFlex>
    </ItemContainer>
  );
};

const QtList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();

  const [wordIndex, setWordIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 3000); // 초당 한번씩 변경

    return () => clearInterval(interval); // 컴포넌트 언마운트시 인터벌 클리어
  }, []);

  const words = [
    "오늘을",
    "하루를",
    "한주를",
    "직장에서",
    "사업에서",
    "가정에서",
    "세상에서",
    "나 자신을",
    "삶을",
    "고통에서",
    "고민에서",
    "상처에서",
  ];

  useEffect(() => {
    const formattedDate = moment(new Date()).format("YYYY-MM-DD");
    const fetchData = async () => {
      try {
        const response = await axios.get(
          // `/list/date=${formattedDate} 00:00:00`
          `https://data.swim.org/list/date=${formattedDate} 00:00:00`
        );
        setData(response.data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <html lang="ko" />
        <title>SWIM QT</title>
        <meta name="description" content="매일 온라인으로 큐티하세요" />
        <meta
          name="keywords"
          content="생명의 삶, 매일성경, 생명의삶, 오늘의 양식, 영상큐티, 영상 큐티, 영어큐티, 다국어 큐티, QT"
        />
      </Helmet>
      <QtListBg>
        <QtListWrap>
          <QtListContainer>
            <Dailyword />
            <QtListTitleFlex>
              <QtListTitleSub>오늘의 QT</QtListTitleSub>
              <TagNav />
            </QtListTitleFlex>
            <QtListGrid>
              {data.map((item, index) => (
                <Item
                  key={index}
                  url={item.url}
                  item={item}
                  color={color[index]}
                />
              ))}
            </QtListGrid>
            <QtListTitleFlex>
              <QtProviderTitle>다양한 QT</QtProviderTitle>
            </QtListTitleFlex>
            <QtListGrid>
              <QtLanguageCard />
              <QtLinkCard />
              <QtChildrenCard />
            </QtListGrid>
          </QtListContainer>
          <QtListContainer>
            <QtListContainer>
              <QtListTitle>
                QT로{" "}
                <AnimatedText key={wordIndex}>{words[wordIndex]}</AnimatedText>{" "}
                승리하세요 !
              </QtListTitle>
            </QtListContainer>
            <QtListTitleFlex>
              <QtProviderTitle>자료제공</QtProviderTitle>
            </QtListTitleFlex>
            <ImgSlider />
          </QtListContainer>
        </QtListWrap>
      </QtListBg>
    </>
  );
};

export default QtList;
