import React, { useState } from "react";
import styled from "styled-components";

const RoadMap = styled.div`
  display: ${({ show }) => (show ? "block" : "none")};
  width: 100%;
`;

const RoadMapTitle = styled.h1`
  cursor: pointer;
  max-width: 1200px;
  min-width: 320px;
`;

const StructureTitle = styled.h1`
  cursor: pointer;
  max-width: 1200px;
  min-width: 320px;
`;

const StructureWrap = styled.div`
  max-width: 1200px;
  width: 90%;
  min-width: 320px;
  display: flex;
  flex-direction: column;
`;

const StructureContentsWrap = styled.div`
  display: ${({ show }) => (show ? "block" : "none")};
  width: 100%;
`;

const Structure = () => {
  const [show, setShow] = useState({ str: false, road: true });

  const structureHandler = () => {
    setShow({ str: !show.str });
  };
  const roadmapHandler = () => {
    setShow({ road: !show.road });
  };

  return (
    <>
      <StructureWrap>
        <StructureTitle onClick={structureHandler}>Structure ▼</StructureTitle>
        <StructureContentsWrap show={show.str}>
          <div style={{ width: "100%" }}>
            <hr />
            <h3>백엔드: </h3>
            <p>Container: aws lightsail instance (5$/month)</p>
            <p>OS: ubuntu server 22.04 LTS</p>
            <p>Server: nginx server</p>
            <p>
              domain/static ip: <a href="http://data.swim.org">data.swim.org</a>{" "}
              /<a href="http://data.swim.org">15.164.120.19</a>
            </p>
            <p>Proxy: nginx reverse proxy :80 to :3000</p>
            <p>Library: Express.js / cors / mysql2 (:3000)</p>
            <p>CORS: express → aws.swim.org</p>
            <p>DBMS: mysql server (:3306)</p>
            <p>
              API: <a href="http://data.swim.org">data.swim.org/api/:params</a>
            </p>
            <div style={{ paddingLeft: "20px" }}>
              <b>
                <p> GET /api/id=? 페이지 가져오기</p>
                <p> POST /api/post (Auth) 글쓰기</p>
                <p> POST /api/login 로그인</p>
                <p> GET /api/posts 작성글 가져오기</p>
                <p> POST /api/refresh 토큰 재발급</p>
              </b>
            </div>
            <p>
              ⚠️ SQL injection 체크되고 있음, 명시된 요청 방식/타입 외에 응답
              안됨. (400 / 200 → error)
            </p>
            <p>⚠️ 1개 IP 초당 10회 요청가능 넘으면 데이터처리 안함.</p>
            <hr />
            <h3>프론트 :</h3>
            <p>Container: aws lightsail instance (5$/month)</p>
            <p>OS: ubuntu server 22.04 LTS</p>
            <p>Server: nginx server</p>
            <p>
              domain/static ip: <a href="http://aws.swim.org">data.swim.org</a>
            </p>
            <p>Proxy: nginx reverse proxy :80 to :3000</p>
            <p>Library: React / styled-components / axios / redux </p>
            <hr />
          </div>
        </StructureContentsWrap>
        <RoadMapTitle onClick={roadmapHandler}>로드맵 ▼</RoadMapTitle>
        <RoadMap show={show.road}>
          <h3>Next:</h3>
          <p>
            TLS(SSL)등록 <b>🟢완료</b>
          </p>
          <p>
            DB생성,연결 <b>🟢완료</b>
          </p>
          <p>
            데이터 바인딩 <b>🟢완료</b>
          </p>
          <p>
            GNB <b>🟢완료</b>
          </p>
          <p>
            JWT access/refresh <b>🟢완료</b>
          </p>
          <p>
            로그인 / 로그아웃 <b>🟢완료</b>
          </p>
          <p>
            라우팅, 에러 페이지(홈 리디렉션) <b>🟢완료</b>
          </p>
          <p>
            모바일 반응성 메뉴 UI <b>🟢완료</b>
          </p>
          <p>
            푸터 후원안내 컴포넌트 <b>🟢완료</b>
          </p>
          <p>
            token 탈취, XSS 방지 (front) <b>🟢완료</b>
          </p>
          <p>
            메일 신청 페이지 <b>🟢완료</b>
          </p>
          <p>
            QT 데이터베이스 백엔드 셋팅 <b>🟢완료</b>
          </p>
          <p>
            QT 페이지 데이터 바인딩 <b>🟡테스트 중</b>
          </p>
          <p>
            언어별 캘린더 변환 자동화 작업 <b>🟢완료</b>
          </p>
          <p>
            데이터캐싱 react-quary적용 (현재:브라우저 캐싱)<b>🔴</b>
          </p>
          <p>
            메뉴릿, 타이틀 링크 데이터베이스, 배열화<b>🔴</b>
          </p>
          <p>
            홈 메인 배너 캐러셀 슬라이드 <b>🔴</b>
          </p>
          <p>
            이미지 업로드 <b>🔴</b>
          </p>
          <p>
            컨텐츠 게시판 <b>🟡테스트 중 db.posts</b>
          </p>
          <p>
            POST,PUSH,DELETE 구현 (RESTful API) <b>🔴</b>
          </p>
          <p>기존 Database Refactoring (정규화) 🔴</p>
        </RoadMap>
      </StructureWrap>
    </>
  );
};

export default Structure;
