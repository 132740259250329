export const SCROLL_DISABLE = "SCROLL_DISABLE";
export const SCROLL_ALLOW = "SCROLL_ALLOW";

export const allowScroll = () => ({ type: SCROLL_ALLOW });
export const disableScroll = () => ({ type: SCROLL_DISABLE });

const initialState = {
  scroll: true,
};

const scrollReducer = (state = initialState, action) => {
  switch (action.type) {
    case SCROLL_ALLOW:
      return { ...state, scroll: true };
    case SCROLL_DISABLE:
      return { ...state, scroll: false };
    default:
      return state;
  }
};

export default scrollReducer;
