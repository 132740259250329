import { RouterProvider } from "react-router-dom";
import { router } from "./Router";
import { useDispatch } from "react-redux";
import { loginSuccess } from "./actions/auth";
import { useEffect } from "react";

const App = () => {
  //토큰 인증 일단 된것으로 설정
  const dispatch = useDispatch();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      dispatch(loginSuccess(token));
    }
  }, [dispatch]);

  return <RouterProvider router={router} />;
};

export default App;
