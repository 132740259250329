import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Posts from "./Posts";
import MainBanner from "../components/MainBanner";
import Ball from "../components/ball";
import { Helmet } from "react-helmet-async";
import ImgSlide from "../components/ImgSlider";

const HomeWrap = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  p {
    color: #777;
  }
`;

const Home = () => {
  const navigate = useNavigate();
  const handleCta = () => {
    navigate("/structure");
  };

  const handle121 = () => {
    window.open("https://121bible.com", "_blank", "noopener,noreferrer");
  };
  return (
    <>
      <Helmet>
        <title>SWIM</title>
        <meta
          name="description"
          content="네트워크 기술로 선교를 연결하고 돕습니다, 온라인 큐티도 제공합니다."
        />
      </Helmet>

      <MainBanner
        imgUrl={"https://data.swim.org/img/subBanner.png"}
        title={"121Bible.com"}
        description={
          "중동아랍권에 보급되는 큐티 서비스! \n 왓츠앱으로 자동발송까지"
        }
        color={"white"}
        layout={"right"}
        ctaText={"GO!"}
        ctaHandle={handle121}
      />

      <MainBanner
        imgUrl={"https://data.swim.org/img/mainBanner.png"}
        title={"사이트 공사중"}
        color={"#0e3975"}
        ctaText={"진행상황"}
        ctaHandle={handleCta}
      />
      <HomeWrap>
        <ImgSlide />
      </HomeWrap>
    </>
  );
};

export default Home;
