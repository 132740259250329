import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import styled, { keyframes } from "styled-components";
import { allowScroll, disableScroll } from "../reducer/scrollReducer";
import { modalOpen } from "../actions/modal";
import Modal from "./modal";
import CalendarPicker from "./calendar";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CalIco } from "../img/calendar.svg";

const slideUp = keyframes`
  0% {
    transform: translateY(80px);
    opacity: 0.7;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const IcoBtnWrap = styled.div`
  width: fit-content;
  height: fit-content;
`;

const Cal = styled(CalIco)`
  width: 60px;
  height: auto;
  margin: 0 10px;
  cursor: pointer;

  path {
    stroke: #0e3975;
  }
  .nano {
    fill: #0e3975;
  }

  @media (max-width: 720px) {
    margin: 0;
  }
`;

const WallBoardWrap = styled.div`
  width: 100%;
  height: 100vh;
  background-color: white;
  z-index: 1001;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  animation: ${slideUp} 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  @media (max-width: 720px) {
    width: 100%;
  }
`;
const WallBoardTabBar = styled.div`
  width: 100%;
  /* height: 180px; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 1003;
  background-color: white;
  border-bottom: 1px solid #ddd;
`;

export const TabButton = styled.button`
  width: 24px;
  height: 24px;
  margin: 28px;
  z-index: 1004;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  border: 0;
  background-color: transparent;
  color: #0e3975;
  cursor: pointer;

  @media (max-width: 720px) {
    margin: 16px;
    font-size: 1.4em;
  }
`;

const TabBtnFlex = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  margin: 28px;
  @media (max-width: 720px) {
    margin: 16px;
  }
`;

const Title = styled.div`
  font-size: 1em;
`;

const ChildWrap = styled.div`
  top: 0;
  overflow-y: scroll;
  height: 100vh - 80px;
  z-index: 1002;
  padding: 0 8px;
  animation: ${slideUp} 0.7s cubic-bezier(0.165, 0.84, 0.44, 1);
`;

const Wallboard = ({ children, title, scroll }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getScrollbarWidth = () => {
    return window.innerWidth - document.documentElement.clientWidth;
  };

  const disableScroll = () => {
    const scrollbarWidth = getScrollbarWidth();
    document.documentElement.style.overflow = "hidden";
    // document.body.style.marginRight = `${scrollbarWidth}px`;
  };

  const enableScroll = () => {
    document.documentElement.style.overflow = "auto";
    // document.body.style.marginRight = "";
  };

  useEffect(() => {
    // 컴포넌트가 마운트될 때 스크롤 비활성화
    disableScroll();

    // 컴포넌트가 언마운트될 때 스크롤 활성화
    return () => {
      enableScroll();
    };
  }, []);

  const closeHandler = () => {
    navigate("/qt");
  };

  const calendarHandler = () => {
    dispatch(modalOpen("calendar"));
  };

  return (
    <WallBoardWrap>
      <WallBoardTabBar>
        <IcoBtnWrap onClick={calendarHandler}>
          <Cal />
        </IcoBtnWrap>
        <Title>{title}</Title>
        <TabButton onClick={closeHandler}>✕</TabButton>
      </WallBoardTabBar>
      <Modal>
        <CalendarPicker />
      </Modal>
      <ChildWrap>{children}</ChildWrap>
    </WallBoardWrap>
  );
};

export default Wallboard;
