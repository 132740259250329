import React, { useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../actions/auth";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const LoginWrap = styled.div`
  width: 100%;
  height: 90vh;
  position: relative;
`;

const Box = styled.div`
  background-color: #071c39;
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: all 0.5s ease-out;
  z-index: -3;
`;

const Gradient = styled.div`
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  z-index: -2;
  width: 500px;
  height: 500px;
  filter: blur(150px);
  background-image: linear-gradient(hsl(222, 84%, 60%), hsl(164, 79%, 71%));
  animation: rotate 10s cubic-bezier(0.8, 0.2, 0.2, 0.8) alternate infinite;
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;

  @media (min-width: 720px) {
    width: 750px;
    height: 750px;
    filter: blur(100px);
  }
`;

const FormWrap = styled.div`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  @media (max-width: 720px) {
    height: auto;
    flex-direction: column;
    align-items: center;
  }
`;

const LoginTitle = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  color: white;
`;

const FormContainer = styled.div`
  margin: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 12px;
  h1 {
    color: #0e3975;
  }
  @media (max-width: 720px) {
    width: 95%;
  }
`;

const SignFrom = styled.form`
  margin: 0 10vw;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const InputTitle = styled.h5`
  margin-block-end: 4px;
  margin-block-start: 0px;
  color: rgb(29 114 235);
  font-size: 0.9em;
  font-weight: 600;
`;

const InputBox = styled.input`
  background-color: white;
  border-radius: 8px;
  border: 1px solid rgba(200, 200, 200, 0.7);
  height: 30px;
  font-weight: 600;
  font-size: 0.9em;
  color: #777;
  padding-left: 8px;
  &::placeholder {
    color: #bbb;
    font-weight: 200;
  }
  &:focus {
    border: 1px solid rgb(29 114 235);
    outline: none;
    box-shadow: 0px 0px 5px 1px rgba(29, 114, 235, 0.2);
  }
`;

const InputCheckText = styled.p`
  padding-left: 4px;
  font-size: 0.8em;
  margin-block-start: 0;
  color: ${({ color }) => color};
  height: ${({ height }) => height};
  transition: height 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
`;

const InputSubmitBtn = styled.button`
  margin: 24px 0;
  border-radius: 8px;
  border: none;
  height: 30px;
  width: 100%;
  text-align: center;
  font-weight: 600;
  color: white;
  background-color: rgb(29 114 235);
  cursor: pointer;
  &:hover {
    background-color: #81adeb;
  }
  &:disabled {
    color: #ddd;
  }
  &:disabled:hover {
    background-color: rgb(76 105 146);
    color: #999;
  }
`;

axios.defaults.withCredentials = true;

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [checkMsg, setCheckMsg] = useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const emailCheckMsg = {
    0: "",
    1: "이메일 형식이 올바르지 않습니다.",
  };

  const validateEmail = (e) => {
    setEmail(e.target.value);
    if (!re.test(String(e.target.value).toLowerCase())) {
      setCheckMsg(1);
    } else {
      setCheckMsg(0);
    }
  };

  const handleLogin = async (e) => {
    if (!email && !password)
      return () => {
        alert("이메일이나 패스워드를 입력하세요.");
      };

    e.preventDefault();
    try {
      const response = await axios.post("https://data.swim.org/api/login", {
        username: email,
        password: password,
      });
      const { token } = response.data;

      await localStorage.setItem("token", token);
      await dispatch(loginSuccess(token));
      await navigate("/");
    } catch (error) {
      alert("로그인 실패: " + error.response.data);
    }
  };

  return (
    <LoginWrap>
      <Box>
        <Gradient />
      </Box>
      <FormWrap>
        <LoginTitle>
          <h1>SWIM</h1>
          <p>
            swim은 세계 선교를 위해 힘쓰며,
            <br />
            온라인 디지털 선교를 제안하고 기술적 보급을 꿈꾸며
            <br />
            전방위로 나섭니다.
          </p>
        </LoginTitle>
        <FormContainer>
          <SignFrom onSubmit={handleLogin}>
            <h1>Welcome</h1>
            <InputTitle>Email</InputTitle>
            <InputBox
              type="email"
              value={email}
              onBlur={validateEmail}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="you@email.com"
            />
            <InputCheckText
              color={checkMsg === 2 ? "green" : "red"}
              height={checkMsg === 0 ? "0px" : "24px"}
            >
              {emailCheckMsg[checkMsg]}
            </InputCheckText>

            <InputTitle>Password</InputTitle>
            <InputBox
              type="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="••••"
            />
            <InputSubmitBtn type="submit" disabled={checkMsg === 1}>
              로그인
            </InputSubmitBtn>
          </SignFrom>
        </FormContainer>
      </FormWrap>
    </LoginWrap>
  );
};

export default Login;
